import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { IconComponent } from '../icon';
import { CountryFlagComponent } from '../country-flag';

@Component({
  selector: 'cca-contact-card',
  imports: [IconComponent, CountryFlagComponent],
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent {
  readonly title = input<string | null>();
  readonly name = input<string | null>();
  readonly email = input<string | null | undefined>('');
  readonly phone = input<string | null | undefined>('');
  readonly contactType = input<string>();
  readonly companyName = input<string | null>();
  readonly flagUrl = input<string | null>();
  readonly avatarURL = input<string | null | undefined>('');

  initials = computed(() => {
    const name = this.name() as string;
    //some good ol' fashioned array manipulation
    const splitName = name.split(' ');
    const firstName = splitName[0];
    const lastName = splitName.slice(1).join(' ');
    return `${firstName?.charAt(0) ?? '?'}${
      lastName?.charAt(0) ?? '?'
    }`.toUpperCase();
  });
}
