import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { type StatusBadgeFlavor } from '@cca-common/core';
import { type Status, StatusesCountComponent } from './statuses-bar';
import { UiStatusBadgeComponent } from '../badges';

@Component({
  selector: 'cca-info-header',
  imports: [UiStatusBadgeComponent, MatButtonModule, StatusesCountComponent],
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoHeaderComponent {
  readonly titlePart1 = input<string | null | undefined>(null);
  readonly titlePart2 = input<string | null>(null);
  readonly badgeText = input<string | null | undefined>(null);
  readonly badgeFlavor = input<StatusBadgeFlavor>('neutral');
  readonly button1Label = input<string | null>(null);
  readonly button1Disabled = input(false);
  readonly button2Label = input<string | null>(null);
  readonly button2Disabled = input(false);
  readonly button3Label = input<string | null>(null);
  readonly button3Disabled = input(false);
  readonly button4Label = input<string | null>(null);
  readonly button4Disabled = input(false);
  readonly statusCountBarData = input<Status[]>();
  readonly description = input<string | null>(null);

  readonly button1Clicked = output<void>();
  readonly button2Clicked = output<void>();
  readonly button3Clicked = output<void>();
  readonly button4Clicked = output<void>();
}
