import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type Observable } from 'rxjs';
import { type GroupVerticalsViewModel } from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class VerticalsService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getGroupVerticals(groupId: id): Observable<GroupVerticalsViewModel> {
    return this.http.get<GroupVerticalsViewModel>(
      `${this.url}detail/verticals/${groupId}`,
    );
  }

  updateVerticals(
    userGroupId: id,
    verticalMainCategoryId: id | null,
    verticalSubCategoryIds: string[],
  ) {
    return this.http.post(`${this.url}update/verticals`, {
      userGroupId,
      verticalMainCategoryId,
      verticalSubCategoryIds,
    });
  }
}
