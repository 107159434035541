import { Injectable, inject } from '@angular/core';
import {
  type ActivatedRouteSnapshot,
  type CanActivate,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { RouterFacade } from '@cca-common/cdk';
import { type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageNotFoundGuard implements CanActivate {
  private router = inject(RouterFacade);

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.router.navigateByUrl('page-not-found', {
      state: { link: state.url },
    });

    return true;
  }
}
