import { HttpContext, type HttpContextToken } from '@angular/common/http';
import {
  AuthenticationContextToken,
  NoAccessContextToken,
  NotFoundContextToken,
  PaginationContextToken,
  SequenceContextToken,
  ServiceWorkerContextToken,
} from './context-tokens';

// For extracting out the value from the HttpContextToken
type HttpContextTokenType<O> = O extends HttpContextToken<infer T> ? T : never;

// Config object for the builder
export interface HttpContextConfig {
  authentication?: HttpContextTokenType<typeof AuthenticationContextToken>;
  pagination?: HttpContextTokenType<typeof PaginationContextToken>;
  serviceWorker?: HttpContextTokenType<typeof ServiceWorkerContextToken>;
  notFound?: HttpContextTokenType<typeof NotFoundContextToken>;
  noAccess?: HttpContextTokenType<typeof NoAccessContextToken>;
  sequence?: HttpContextTokenType<typeof NoAccessContextToken>;
}

// Using the config object we build the type
type HttpContextMapConfig = {
  [key in keyof HttpContextConfig]: HttpContextToken<HttpContextConfig[key]>;
};
const httpContextMap: HttpContextMapConfig = {
  authentication: AuthenticationContextToken,
  pagination: PaginationContextToken,
  serviceWorker: ServiceWorkerContextToken,
  noAccess: NoAccessContextToken,
  notFound: NotFoundContextToken,
  sequence: SequenceContextToken,
};

export function HttpContextBuilder(config: HttpContextConfig) {
  // setup a HttpContext
  const context = new HttpContext();

  // loop over the config and find the matching tokens
  for (const [key, value] of Object.entries(config)) {
    const token = httpContextMap[key as keyof HttpContextConfig];

    // if the token is found, add it to the context with the specified value from config.
    if (token) {
      context.set(token, value);
    } else {
      throw new Error(
        `HttpContextBuilder: unknown key ${key} in configuration`,
      );
    }
  }
  return context;
}
