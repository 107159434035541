/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NotRequired  1 = Pending  2 = Confirmed  -1 = Cancelled
 */
export const TimeSlotsStatus = {
  NotRequired: 0,
  Pending: 1,
  Confirmed: 2,
  Cancelled: -1,
} as const;

export type TimeSlotsStatus =
  (typeof TimeSlotsStatus)[keyof typeof TimeSlotsStatus];

/**
 * Maps a TimeSlotsStatus enumeration value to its corresponding translation key.
 *
 * @param { TimeSlotsStatus } timeSlotsStatus -
 *        The enumeration value representing the type of TimeSlotsStatus.
 * @returns {string} The translation key corresponding to the given TimeSlotsStatus.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('TimeSlotsStatus')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromTimeSlotsStatus(TimeSlotsStatus.Pending);
 * console.log(translationKey); // 'TimeSlotsStatus.Pending'
 * console.log(translocoService.translate(translationKey)) // "Translation of TimeSlotsStatus.Pending"
 *
 * // using in a template:
 * {{ translationKeyFromTimeSlotsStatus(TimeSlotsStatus.Pending) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('TimeSlotsStatus')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromTimeSlotsStatus(
  timeSlotsStatus: TimeSlotsStatus,
) {
  switch (timeSlotsStatus) {
    case TimeSlotsStatus.NotRequired:
      return 'TimeSlotsStatus.NotRequired';
    case TimeSlotsStatus.Pending:
      return 'TimeSlotsStatus.Pending';
    case TimeSlotsStatus.Confirmed:
      return 'TimeSlotsStatus.Confirmed';
    case TimeSlotsStatus.Cancelled:
      return 'TimeSlotsStatus.Cancelled';
    default:
      return '';
  }
}
