<section class="flex flex-col gap-2">
  <h3>
    {{ title() }}
  </h3>
  <div
    class="mr-1 flex items-center gap-1 text-sm font-medium text-neutral-body"
  >
    <ng-content></ng-content>
  </div>
</section>
