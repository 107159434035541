import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'cca-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrl: './country-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagComponent {
  readonly url = input.required<(string | null) | undefined>();
}
