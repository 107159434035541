import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type GroupLicencesViewModel,
  type UpdateGroupLicencesRequest,
} from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GroupLicencesService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getLicences(groupId: id) {
    return this.http.get<GroupLicencesViewModel>(
      `${this.url}detail/licences/${groupId}`,
    );
  }

  updateLicences(licences: UpdateGroupLicencesRequest) {
    return this.http.post(`${this.url}update/licences`, licences);
  }
}
