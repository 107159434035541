import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  output,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { IconComponent } from '../icon';

type Flavor = 'primary' | 'warning' | 'highlight' | 'danger' | 'default';

@Component({
  selector: 'cca-info-banner',
  imports: [NgClass, IconComponent],
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class InfoBannerComponent {
  private host = inject<ElementRef<HTMLElement>>(ElementRef);

  readonly flavor = input<Flavor>('primary');
  readonly title = input('');
  readonly description = input('');
  readonly showIcon = input(true);
  readonly closable = input(false);
  readonly fullWidth = input(false);
  readonly showButton = input(false);
  readonly buttonLabel = input('');
  readonly buttonClicked = output();

  close() {
    this.host.nativeElement.remove();
  }

  buttonClick() {
    this.buttonClicked.emit();
  }
}
