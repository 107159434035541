import {
  Component,
  ChangeDetectionStrategy,
  type OnChanges,
  type SimpleChanges,
  input,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressHelper } from '@cca-common/core';
import { CdkTextLimiterPipe } from '@cca-common/cdk';
import { type ChainCargoAddress, isChainCargoAddress } from '@cca-infra/common';

@Component({
  imports: [CdkTextLimiterPipe, MatTooltipModule],
  selector: 'cca-address-text',
  templateUrl: './address-text.component.html',
  styleUrls: ['./address-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAddressTextComponent implements OnChanges {
  readonly address = input<ChainCargoAddress | null | unknown>();

  addressFormatLines: string[] = [];
  readonly maxCharacterLength = input(24);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address) {
      this.addressFormatLines = AddressHelper.formatAddressToLines(
        this.address() as ChainCargoAddress,
        {
          excludeLocationName: true,
        },
      );
    }
  }

  get locationName() {
    const address = this.address();
    if (isChainCargoAddress(address)) {
      return address.locationName;
    }

    return null;
  }
}
