<div class="flex w-full items-start gap-5">
  <div
    class="mt-[0.1875rem] grid h-10 w-10 flex-shrink-0 place-items-center overflow-hidden rounded-full text-base font-medium info-text-dark"
    [ngClass]="noContact() ? 'surface-neutral-dark' : 'info-surface-light'"
  >
    @if (noContact()) {
      <cca-icon class="text-lg text-neutral-body" icon="user"></cca-icon>
    } @else {
      @let initials = this.initials();
      @if (avatarURL()) {
        <img [src]="avatarURL()" />
      } @else if (initials) {
        {{ initials }}
      } @else {
        <cca-icon icon="user" class="text-2xl" />
      }
    }
  </div>

  <div class="flex grow flex-col gap-2 text-sm text-neutral-body">
    <div class="flex flex-shrink-0 flex-col gap-1 font-medium empty:hidden">
      @if (noContact() || fullName()) {
        <p
          class="break-all text-base"
          [ngClass]="noContact() ? 'text-neutral-caption' : 'text-neutral-body'"
        >
          @if (noContact()) {
            {{ noContactText() }}
          } @else {
            {{ fullName() }}
          }
        </p>
      }
      @if (flagImageUrl() || responsibilities()) {
        <p class="flex items-center gap-2 text-xs text-neutral-caption">
          @if (flagImageUrl()) {
            <cca-country-flag
              class="max-h-3 max-w-3"
              [url]="flagImageUrl()"
            ></cca-country-flag>
          }
          {{ responsibilities() ?? '-' }}
        </p>
      }
    </div>

    @let enterpriseName = this.enterpriseName();
    @if (enterpriseName) {
      <div class="flex items-center gap-2 break-all font-normal">
        <cca-icon icon="enterprise"></cca-icon>
        <span>
          {{ enterpriseName }}
        </span>
      </div>
    }

    @let groupName = this.groupName();
    @if (groupName) {
      <div class="flex items-center gap-2 break-all font-normal">
        <cca-icon icon="group"></cca-icon>
        <span>
          {{ groupName }}
        </span>
      </div>
    }
    <div class="flex flex-wrap gap-x-4 gap-y-2">
      @let phoneNumber = this.phoneNumber();
      @if (phoneNumber) {
        <div class="flex grow items-center gap-2">
          <cca-icon icon="phone"></cca-icon>
          <a
            href="tel:{{ phoneNumber }}"
            class="font-medium underline text-brand-default"
            >{{ phoneNumber }}</a
          >
        </div>
      }

      @let email = this.email();
      @if (email) {
        <div class="flex grow items-center gap-2">
          <cca-icon icon="mail"></cca-icon>
          <a
            href="mailto:{{ email }}"
            class="break-all font-medium underline text-brand-default"
            >{{ email }}</a
          >
        </div>
      }
    </div>
  </div>
</div>
