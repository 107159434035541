import { Injectable } from '@angular/core';
import { type id } from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import { type CreateBookingViewModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends BaseApiService {
  constructor() {
    super(`request.v1.booking`);
  }

  createLaneBooking(userSequenceStateId: id) {
    return this.http.post<CreateBookingViewModel>(
      `${this.url}create-lane-booking`,
      { userSequenceStateId },
    );
  }

  createCourierBooking(userSequenceStateId: id) {
    return this.http.post<CreateBookingViewModel>(
      `${this.url}create-courier-booking`,
      {
        userSequenceStateId,
      },
    );
  }

  createFTLBooking(userSequenceStateId: id) {
    return this.http.post<CreateBookingViewModel>(
      `${this.url}create-ftl-booking`,
      {
        userSequenceStateId,
      },
    );
  }

  createTenderBooking(userSequenceStateId: id) {
    return this.http.post<CreateBookingViewModel>(
      `${this.url}create-tender-booking`,
      {
        userSequenceStateId,
      },
    );
  }

  createFCLBooking(userSequenceStateId: id) {
    return this.http.post<CreateBookingViewModel>(
      `${this.url}create-fcl-booking`,
      {
        userSequenceStateId,
      },
    );
  }
}
