import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type AdhocBasicTenderDetailViewModel,
  type AdhocBasicTenderShipmentBidViewModel,
  type CarrierResponseStatus,
  type PaginationAdhocTenderRequestViewModel,
} from '../model';

export interface DefaultTenderRequestModel {
  requestId: string | null;
  orderId: string | null;
}

@Injectable({ providedIn: 'root' })
export class TenderV2Service extends BaseApiService {
  constructor() {
    super(`tender.v2.request`);
  }

  tenderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationAdhocTenderRequestViewModel>
    >(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  basicTenderDetails(tenderReferenceNumber: string) {
    return this.http.post<AdhocBasicTenderDetailViewModel>(
      `${this.url}get-basic-tender-details`,
      {
        tenderReferenceNumber,
      },
    );
  }

  basicTenderDetailsUnauthorized(
    hash: string | undefined,
    tenderReferenceNumber: string,
  ) {
    return this.http.post<AdhocBasicTenderDetailViewModel>(
      `${this.url}get-basic-tender-details-unauthorized`,
      {
        hash,
        tenderReferenceNumber,
      },
    );
  }

  basicTenderShipmentBids(
    carrierResponseStatus: CarrierResponseStatus,
    tenderReferenceNumber: string,
    iterationId?: id | undefined | null,
  ) {
    return this.http.post<AdhocBasicTenderShipmentBidViewModel>(
      `${this.url}get-basic-tender-shipment-bids`,
      {
        iterationId,
        carrierResponseStatus,
        tenderReferenceNumber,
      },
    );
  }

  basicTenderShipmentBidsUnauthorized(
    carrierResponseStatus: CarrierResponseStatus,
    tenderReferenceNumber: string,
    hash: string | undefined,
    iterationId?: id | undefined | null,
  ) {
    return this.http.post<AdhocBasicTenderShipmentBidViewModel>(
      `${this.url}get-basic-tender-shipment-bids-unauthorized`,
      {
        hash,
        iterationId,
        carrierResponseStatus,
        tenderReferenceNumber,
      },
    );
  }

  tenderMTPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationAdhocTenderRequestViewModel>
    >(`${this.url}paginated-managed-transport-tenders`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  tenderMTPaginationFilters() {
    return this.http.get(
      `${this.url}paginated-managed-transport-tenders-filters`,
    );
  }
}
