import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  type PaginationResponse,
  type PaginationRequest,
  type PaginationRequestParameters,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import { type PaginatedTripsItemViewModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class TripService extends BaseApiService {
  constructor() {
    super(`trip.v1.trip`);
  }

  pagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<PaginatedTripsItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  paginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }
}
