/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = At  2 = Between  3 = Before  4 = After
 */
export const TimeIndicationType = {
  Undefined: 0,
  At: 1,
  Between: 2,
  Before: 3,
  After: 4,
} as const;

export type TimeIndicationType =
  (typeof TimeIndicationType)[keyof typeof TimeIndicationType];

/**
 * Maps a TimeIndicationType enumeration value to its corresponding translation key.
 *
 * @param { TimeIndicationType } timeIndicationType -
 *        The enumeration value representing the type of TimeIndicationType.
 * @returns {string} The translation key corresponding to the given TimeIndicationType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('TimeIndicationType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromTimeIndicationType(TimeIndicationType.At);
 * console.log(translationKey); // 'TimeIndicationType.At'
 * console.log(translocoService.translate(translationKey)) // "Translation of TimeIndicationType.At"
 *
 * // using in a template:
 * {{ translationKeyFromTimeIndicationType(TimeIndicationType.At) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('TimeIndicationType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromTimeIndicationType(
  timeIndicationType: TimeIndicationType,
) {
  switch (timeIndicationType) {
    case TimeIndicationType.Undefined:
      return 'TimeIndicationType.Undefined';
    case TimeIndicationType.At:
      return 'TimeIndicationType.At';
    case TimeIndicationType.Between:
      return 'TimeIndicationType.Between';
    case TimeIndicationType.Before:
      return 'TimeIndicationType.Before';
    case TimeIndicationType.After:
      return 'TimeIndicationType.After';
    default:
      return '';
  }
}
