import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { type CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../../icon';

export enum Flavor {
  Primary,
  Secondary,
}

interface Translations {
  stop: string;
}

export interface OrderActionLike {
  label: string | undefined;
  shortDescription: string;
  iterationNumber: undefined;
  reDoActionLabel: undefined;
}

@Component({
  imports: [NgClass, IconComponent, MatRadioModule],
  selector: 'cca-action-header',
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHeaderComponent {
  Flavor = Flavor;
  readonly action = input<OrderActionLike>();
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  readonly icon = input<CtrlChainIcons>();
  readonly translations = input<Translations>({
    stop: '',
  });
  readonly flavor = input<Flavor>(Flavor.Primary);

  get stopNumber() {
    return 0;
  }
}
