import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OverviewDetailComponent } from '../overview-detail/overview-detail.component';
import { type CtrlChainIcons } from '@cca-common/core';
import { IconLabelGroupComponent } from '../../icon-label-group';

@Component({
  selector: 'cca-overview-shipper',
  templateUrl: './overview-shipper.component.html',
  styleUrls: ['./overview-shipper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OverviewDetailComponent, IconLabelGroupComponent],
})
export class OverviewShipperComponent {
  readonly shipperTitle = input.required<string>();
  readonly enterpriseIcon = input.required<CtrlChainIcons>();
  readonly enterpriseLabel = input.required<string>();
  readonly groupIcon = input.required<CtrlChainIcons>();
  readonly groupLabel = input.required<string>();
  readonly userTitle = input.required<string>();
  readonly userName = input.required<string>();
}
