/**
 * User API
 * <p>The User API is used to manage users of the platform. Users van login, change passwords.                                      It will also provide an access and refresh token which is used for other API access.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Read  2 = Create  4 = Update  8 = Delete  16 = Offer  32 = Refuse  64 = Accept  128 = Reject  256 = Open  512 = Close  1024 = Bid
 */
export const PermissionType = {
  Undefined: 0,
  Read: 1,
  Create: 2,
  Update: 4,
  Delete: 8,
  Offer: 16,
  Refuse: 32,
  Accept: 64,
  Reject: 128,
  Open: 256,
  Close: 512,
  Bid: 1024,
} as const;

export type PermissionType =
  (typeof PermissionType)[keyof typeof PermissionType];

/**
 * Maps a PermissionType enumeration value to its corresponding translation key.
 *
 * @param { PermissionType } permissionType -
 *        The enumeration value representing the type of PermissionType.
 * @returns {string} The translation key corresponding to the given PermissionType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('PermissionType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromPermissionType(PermissionType.Read);
 * console.log(translationKey); // 'PermissionType.Read'
 * console.log(translocoService.translate(translationKey)) // "Translation of PermissionType.Read"
 *
 * // using in a template:
 * {{ translationKeyFromPermissionType(PermissionType.Read) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('PermissionType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromPermissionType(
  permissionType: PermissionType,
) {
  switch (permissionType) {
    case PermissionType.Undefined:
      return 'PermissionType.Undefined';
    case PermissionType.Read:
      return 'PermissionType.Read';
    case PermissionType.Create:
      return 'PermissionType.Create';
    case PermissionType.Update:
      return 'PermissionType.Update';
    case PermissionType.Delete:
      return 'PermissionType.Delete';
    case PermissionType.Offer:
      return 'PermissionType.Offer';
    case PermissionType.Refuse:
      return 'PermissionType.Refuse';
    case PermissionType.Accept:
      return 'PermissionType.Accept';
    case PermissionType.Reject:
      return 'PermissionType.Reject';
    case PermissionType.Open:
      return 'PermissionType.Open';
    case PermissionType.Close:
      return 'PermissionType.Close';
    case PermissionType.Bid:
      return 'PermissionType.Bid';
    default:
      return '';
  }
}
