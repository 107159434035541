<div
  class="item-center inline-flex gap-1 rounded-lg px-2 py-1 text-sm font-medium"
  [ngClass]="flavor()"
>
  @let icon = this.icon();
  @if (icon) {
    <cca-icon [icon]="icon"></cca-icon>
  }
  <span>
    {{ labelName() }}
  </span>
</div>
