<div class="flex gap-3">
  <section class="flex min-w-64 flex-col gap-2 @2xl:min-w-96">
    <h3>
      {{ shipperTitle() }}
    </h3>
    <cca-icon-label-group
      class="font-medium text-neutral-body"
      [firstIcon]="enterpriseIcon()"
      [firstLabel]="enterpriseLabel()"
      [secondIcon]="groupIcon()"
      [secondLabel]="groupLabel()"
    >
    </cca-icon-label-group>
  </section>
  <section>
    <cca-overview-detail [title]="userTitle()"> </cca-overview-detail>
  </section>
</div>
