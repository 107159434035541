import { Directive, HostListener, inject, input } from '@angular/core';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';

@Directive({
  selector: 'input[ccaLocaleNumberInput]',
  exportAs: 'ccaLocaleNumberInput',
})
export class LocaleNumberInputDirective {
  private localeService = inject(TranslocoLocaleService);

  allowNegative = input(false); // New input property

  // Helper function to get the decimal separator based on locale
  private getDecimalSeparator(): string {
    const decimalSeparator = Intl.NumberFormat(this.localeService.getLocale())
      .formatToParts(1.1)
      .find((part) => part.type === 'decimal')?.value;

    return decimalSeparator || '.'; // Default to '.' if nothing is found
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const decimalSeparator = this.getDecimalSeparator();

    // Build allowed character set based on the `allowNegative` property
    const allowedChars = this.allowNegative()
      ? `0123456789${decimalSeparator}-`
      : `0123456789${decimalSeparator}`;
    const inputChar = String.fromCharCode(event.charCode);

    // Prevent input if it contains invalid characters (no thousand separators)
    if (!allowedChars.includes(inputChar)) {
      event.preventDefault();
    }
  }

  // Handle paste event to clean up the input (remove thousand separators)
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const decimalSeparator = this.getDecimalSeparator();
    const clipboardData = event.clipboardData?.getData('text');

    if (clipboardData) {
      // Remove all thousand separators (commas or dots, depending on locale) and ensure correct decimal separator
      const cleanInput = clipboardData.replace(/[.,]/g, (match) =>
        match === decimalSeparator ? decimalSeparator : '',
      );

      // Modify the regex based on whether negative values are allowed
      const regex = this.allowNegative()
        ? /^-?[0-9]*[.,]?[0-9]*$/
        : /^[0-9]*[.,]?[0-9]*$/;

      // If the cleaned input isn't a valid number, prevent the paste action
      if (!regex.test(cleanInput)) {
        event.preventDefault();
      }
    }
  }
}
