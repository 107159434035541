import { Pipe, type PipeTransform } from '@angular/core';

/**
 * Typed filter function.
 */
export type FilterFn<I> = (item1: I) => boolean;

@Pipe({
  name: 'filter',
})
export class CdkFilterPipeModule implements PipeTransform {
  transform<T, Fn extends FilterFn<T>>(value: T[], filterFn: Fn): T[] {
    return value.filter(filterFn);
  }
}
