<div class="flex flex-col gap-2">
  <h3>{{ questionLabel() }}</h3>
  <mat-radio-group
    aria-label="Select an option"
    color="primary"
    [formControl]="formCtrl()"
    (change)="selectionChanged.emit($event.value)"
  >
    <mat-radio-button [value]="true" color="primary" [disabled]="disabled()">{{
      button1Label()
    }}</mat-radio-button>
    <mat-radio-button [value]="false" [disabled]="disabled()">{{
      button2Label()
    }}</mat-radio-button>
  </mat-radio-group>
</div>
