import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { IconComponent } from '../icon';

@Component({
  imports: [MatButtonModule, IconComponent, TranslocoModule],
  selector: 'cca-phone-button',
  templateUrl: './phone-button.component.html',
  styleUrls: ['./phone-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('phoneButton')],
})
export class PhoneButtonComponent {
  readonly phoneNumber = input('');
}
