<label
  class="relative flex h-full cursor-pointer overflow-hidden rounded border px-4 py-3 surface-neutral-light border-neutral-default hover:!surface-brand-lightest hover:!border-brand-default"
  [ngClass]="{
    '!border-brand-default': isSelected(),
  }"
>
  <div class="flex h-full items-center gap-3">
    <mat-radio-button
      [value]="value()"
      color="primary"
      [checked]="isSelected()"
      (change)="radioChanged.emit(value() ?? null)"
    ></mat-radio-button>

    <cca-action-header
      [action]="action"
      icon="user"
      class="mb-0"
      [flavor]="Flavor.Secondary"
    ></cca-action-header>
  </div>
</label>
