import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type ActivateGroupTypeRequest,
  type DeactivateGroupTypeRequest,
  type DeactivationReasonViewModel,
  type UpdateDueDiligenceCheckDateRequest,
  type UpdateDueDiligenceDeactivationReasonRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class GroupStatusService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getDeactivationReasons(domainGroupType: number) {
    return this.http.get<DeactivationReasonViewModel[]>(
      `${this.url}get-deactivation-reasons/${domainGroupType}`,
    );
  }

  activateGroupType(activateGroupTypeRequest: ActivateGroupTypeRequest) {
    return this.http.post<ActivateGroupTypeRequest>(
      `${this.url}activate-group-type`,
      activateGroupTypeRequest,
    );
  }

  deactivateGroupType(activateGroupTypeRequest: DeactivateGroupTypeRequest) {
    return this.http.post<DeactivateGroupTypeRequest>(
      `${this.url}deactivate-group-type`,
      activateGroupTypeRequest,
    );
  }

  updateCheckDate(
    activateGroupTypeRequest: UpdateDueDiligenceCheckDateRequest,
  ) {
    return this.http.post<UpdateDueDiligenceCheckDateRequest>(
      `${this.url}update/due-diligence-check-date`,
      activateGroupTypeRequest,
    );
  }

  updateDeactivationReason(
    activateGroupTypeRequest: UpdateDueDiligenceDeactivationReasonRequest,
  ) {
    return this.http.post<UpdateDueDiligenceDeactivationReasonRequest>(
      `${this.url}update/due-diligence-deactivation-reason`,
      activateGroupTypeRequest,
    );
  }
}
