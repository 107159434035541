<mat-tree
  [dataSource]="dataSource()"
  [treeControl]="treeControl()"
  class="list-none"
>
  <mat-tree-node *matTreeNodeDef="let node">
    <li
      class="mat-tree-node border-b pl-12 text-base font-medium border-neutral-default text-neutral-title"
    >
      <mat-radio-button
        class="pointer-events-auto pr-2"
        color="primary"
        (change)="radioToggle(node)"
        [checked]="node.id === selectedId()"
        ><span class="text-base font-medium">{{ node.name }}</span>

        @if (node.isEnterprise) {
          <span class="text-base font-normal text-neutral-caption">
            ({{ enterpriseLabel() }})
          </span>
        }
      </mat-radio-button>
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div
      class="mat-tree-node flex cursor-pointer gap-2 border-b border-neutral-default"
      [ngClass]="{
        'surface-neutral-default': treeControl().isExpanded(node),
      }"
      matTreeNodeToggle
    >
      <button mat-icon-button [attr.aria-label]="'toggle ' + node.name">
        <cca-icon
          class="text-xl text-brand-default"
          [icon]="
            treeControl().isExpanded(node) ? 'chevron-up' : 'chevron-down'
          "
        >
        </cca-icon>
      </button>
      <span class="text-base font-medium text-neutral-body"
        >{{ node.name }}
        @if (!node.openActions) {
          <span> ({{ node.childCount }}) </span>
        }
        &nbsp;
      </span>
    </div>

    @if (node.loading) {
      <cca-spinner class="h-8 w-8"></cca-spinner>
    }
    <ul [class.hidden]="!treeControl().isExpanded(node)">
      <ng-container matTreeNodeOutlet></ng-container>
    </ul>
  </mat-nested-tree-node>
</mat-tree>
