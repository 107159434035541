import {
  Component,
  ChangeDetectionStrategy,
  output,
  input,
  computed,
} from '@angular/core';
import { IconComponent } from '../icon';

@Component({
  imports: [IconComponent],
  selector: 'cca-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {
  readonly name = input<string | null>();
  readonly firstName = input('');
  readonly lastName = input('');
  readonly email = input('');
  readonly phone = input('');
  readonly companyName = input<string | null>();
  readonly enterpriseName = input<string | null>();
  readonly contactType = input<string>();
  readonly shouldShowCompany = input(true);
  readonly shouldShowEnterprise = input(true);
  readonly avatarURL = input<string | null | undefined>('');
  readonly showManagers = output<void>();

  initials = computed(() => {
    let firstName = this.firstName();
    let lastName = this.lastName();
    if (!firstName || !lastName) {
      const name = this.name();
      if (name) {
        //some good ol' fashioned array manipulation
        const splitName = name.split(' ');
        firstName = splitName[0];
        lastName = splitName.slice(1).join(' ');
        return `${firstName?.charAt(0) ?? '?'}${
          lastName?.charAt(0) ?? '?'
        }`.toUpperCase();
      }
    }

    return `${firstName?.charAt(0) ?? '?'}${
      lastName?.charAt(0) ?? '?'
    }`.toUpperCase();
  });

  handleClick() {
    this.showManagers.emit();
  }
}
