import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseApiService {
  constructor() {
    super(`tender.v1.analytics`);
  }

  generateBuyPricePredictionLink(requestId: id) {
    return this.http.post<{ powerBILink: string }>(
      `${this.url}generate-buyprice-prediction-link`,
      {
        requestId,
      },
    );
  }
}
