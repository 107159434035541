/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  type EmbeddedViewRef,
  HostBinding,
  HostListener,
  Input,
  type OnChanges,
  type TemplateRef,
  ViewContainerRef,
  inject,
  output,
} from '@angular/core';

@Directive({
  selector: '[ccaDetailRow]',
  exportAs: 'ccaDetailRow',
})
export class CdkDetailRowDirective implements OnChanges {
  vcRef = inject(ViewContainerRef);

  readonly openedChanged = output<boolean>();
  private row: any;
  private index: number | undefined;
  private tRef: TemplateRef<any> | undefined; //template
  public opened = false;
  embeddedViewRef: EmbeddedViewRef<any> | undefined; //instance of template
  private disabled = false;
  _ccaDetailRowExpandedClass = '';

  @HostBinding('class.expanded')
  get expanded(): boolean {
    return this.opened;
  }

  @Input()
  @HostBinding('class')
  get ccaDetailRowExpandedClass() {
    if (this.opened) {
      return `${this?._ccaDetailRowExpandedClass ?? ''} expanded`;
    }
    return '';
  }

  set ccaDetailRowExpandedClass(value: string) {
    this._ccaDetailRowExpandedClass = value;
  }

  @Input() set ccaDetailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
    }
  }

  @Input() set ccaDetailRowOpened(value: any) {
    if (value !== this.opened) {
      this.opened = value;
    }
  }

  @Input() set ccaDetailRowIndex(value: any) {
    if (value !== this.index) {
      this.index = value;
    }
  }

  @Input() set ccaDetailRowDisabled(disabled: any) {
    this.disabled = disabled;
  }

  @Input() set ccaDetailRowTemplate(value: TemplateRef<any> | undefined) {
    if (value !== this.tRef) {
      this.tRef = value;

      if (this.opened === true) {
        this.opened = false;
        this.toggle();
      }
    }
  }

  @HostListener('click')
  onClick(): void {
    if (!this.disabled) {
      this.toggle();
    }
  }

  toggle(): void {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }
    this.opened = this.vcRef.length > 0;
    this.openedChanged.emit(this.opened);
  }

  private render(): void {
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.embeddedViewRef = this.vcRef.createEmbeddedView(this.tRef, {
        $implicit: this.row,
        index: this.index,
      });
    }
  }

  ngOnChanges(): void {
    if (this.opened && this.embeddedViewRef) {
      this.embeddedViewRef.context.$implicit = this.row;
      this.embeddedViewRef.context.row = this.row;
      this.embeddedViewRef.context.index = this.index;
      this.embeddedViewRef.markForCheck();
    }
  }
}
