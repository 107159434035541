import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  type AuditLogItemViewModel,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  type LaneRowDetailViewModel,
  type LanesRequestDetailViewModel,
  type LanesRequestPaginationItem,
  type LanesRowPaginationItem,
  type RequestContactViewModel,
  type UpdateLaneRowCargoInformationRequest,
  type UpdateLaneRowLocationRequest,
  type UpdateLaneRowToInternallyRefusedRequest,
  type UpdateLaneRowVolumeFrequencyRequest,
  type UpdateLanesRequestRequest,
  type GetLanesTenderForCreateRequest,
  type GetLanesForCreateOfferRequest,
  type SetLaneRowsOfferedPricesRequest,
  type RespondToOfferRequest,
  type LaneRowOfferViewModel,
  type GenerateLanesRequest,
} from '../model';
import {
  type UpdateLaneRowEquipmentV2Request,
  type UpdateLaneRowMinimumInsuranceV2Request,
  type UpdateLaneRowParkingRequirementsV2Request,
  type UpdateLaneRowPolicyDetailsV2Request,
} from '@cca-infra/request-management/v2';

@Injectable({
  providedIn: 'root',
})
export class LaneRequestService extends BaseApiService {
  constructor() {
    super('request.v1.laneRequest');
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LanesRequestPaginationItem>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  allPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  laneRowsPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LanesRowPaginationItem>>(
      `${this.url}lane-row/paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  laneRowsPaginatedFilters() {
    return this.http.get<FilterInfo>(`${this.url}lane-row/paginated-filters`);
  }

  laneRowsExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}lane-row/export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  laneRowDuplicate(laneRowId: id) {
    return this.http.post<{
      referenceNumber: string;
      id: id;
    }>(`${this.url}lane-row/duplicate`, {
      laneRowId: laneRowId,
    });
  }

  offersPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LaneRowOfferViewModel>>(
      `${this.url}lane-row/paginated-offers`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  respondToOffer(request: RespondToOfferRequest) {
    return this.http.post<LaneRowOfferViewModel>(
      `${this.url}lane-row/respond-to-offer`,
      request,
    );
  }

  getLaneRequestDetail(referenceNumber: string) {
    return this.http.get<LanesRequestDetailViewModel>(
      `${this.url}detail/${referenceNumber}`,
    );
  }

  updateLaneRequest(request: UpdateLanesRequestRequest) {
    return this.http.post<LanesRequestDetailViewModel>(
      `${this.url}update`,
      request,
    );
  }

  pushLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}push`, {
      lanesRequestId: laneRequestId,
    });
  }

  pushBackLaneRequest(laneRequestId: id, pushBackReason: string) {
    return this.http.post(`${this.url}push-back`, {
      laneRequestId: laneRequestId,
      pushBackReason: pushBackReason,
    });
  }

  setReminderLaneRequest(laneRequestId: id, reminderDate: Date) {
    return this.http.post(`${this.url}set-reminder`, {
      laneRequestId: laneRequestId,
      reminderDate: reminderDate,
    });
  }

  acceptLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}accept`, {
      laneRequestId: laneRequestId,
    });
  }

  cancelLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}cancel`, {
      lanesRequestId: laneRequestId,
    });
  }

  getEventLog(laneRequestId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${laneRequestId}`,
    );
  }

  getContacts(laneRequestId: id) {
    return this.http.get<RequestContactViewModel[]>(
      `${this.url}contacts/${laneRequestId}`,
    );
  }

  updateVolumeFrequency(request: UpdateLaneRowVolumeFrequencyRequest) {
    return this.http.post<boolean>(
      `${this.url}lane-row/update-volume-frequency`,
      request,
    );
  }

  updateCargo(request: UpdateLaneRowCargoInformationRequest) {
    return this.http.post(
      `${this.url}lane-row/update-cargo-information`,
      request,
    );
  }

  updateEquipment(request: UpdateLaneRowEquipmentV2Request) {
    return this.http.post(`${this.url}lane-row/update-equipment`, request);
  }

  updateParking(request: UpdateLaneRowParkingRequirementsV2Request) {
    return this.http.post(
      `${this.url}lane-row/update-parking-requirements`,
      request,
    );
  }

  updateInsurance(request: UpdateLaneRowMinimumInsuranceV2Request) {
    return this.http.post(
      `${this.url}lane-row/update-minimum-insurance`,
      request,
    );
  }

  updatePolicyDetails(request: UpdateLaneRowPolicyDetailsV2Request) {
    return this.http.post(`${this.url}lane-row/update-policy-details`, request);
  }

  updateLocation(request: UpdateLaneRowLocationRequest) {
    return this.http.post(`${this.url}lane-row/update-location`, request);
  }

  deleteRow(lanRowId: id) {
    return this.http.delete<boolean>(`${this.url}lane-row/${lanRowId}`);
  }

  refuseToServeToSales(request: UpdateLaneRowToInternallyRefusedRequest) {
    return this.http.post<LaneRowDetailViewModel>(
      `${this.url}lane-row/update-to-internally-refused`,
      request,
    );
  }

  getTenderOwner(laneRowId: id) {
    return this.http.get<RequestContactViewModel>(
      `${this.url}lane-row/tender-owner/${laneRowId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  laneDetailsForTenderCreate(request: GetLanesTenderForCreateRequest) {
    return this.http.post(
      `${this.url}lane-row/detail-for-create-tender`,
      request,
    );
  }

  laneDetailsForOfferCreate(request: GetLanesForCreateOfferRequest) {
    return this.http.post(
      `${this.url}lane-row/details-for-offer-creation`,
      request,
    );
  }

  lanesSetOfferedPrices(request: SetLaneRowsOfferedPricesRequest) {
    return this.http.post(`${this.url}lane-row/set-offered-prices`, request);
  }

  generateLanes(request: GenerateLanesRequest) {
    return this.http.post(`${this.url}lane-row/generate-lanes`, request);
  }
}
