import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'cca-overview-detail',
  imports: [],
  templateUrl: './overview-detail.component.html',
  styleUrls: ['./overview-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewDetailComponent {
  readonly title = input<string | null>();
}
