import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'cca-radio-button-card',
  imports: [MatRadioModule],
  templateUrl: './radio-button-card.component.html',
  styleUrls: ['./radio-button-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonCardComponent {
  readonly title = input('');
  readonly description = input('');
  readonly image = input('');
  readonly value = input.required<number | undefined>();
  readonly checked = input.required<boolean>();
}
