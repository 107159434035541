@if (vertical()) {
  <div
    class="flex flex-col justify-center gap-6 overflow-hidden rounded text-center surface-neutral-light"
  >
    <img class="mx-auto h-16 w-16" [src]="imageSrc()" />
    <h4 class="break-words text-xl font-bold text-neutral-body">
      {{ title() }}
    </h4>
    <p class="text-s break-words font-normal text-neutral-body">
      {{ description() }}
    </p>
  </div>
} @else {
  <div
    class="flex flex-row justify-start gap-6 overflow-hidden rounded surface-neutral-light"
  >
    <div class="flex h-16 w-16 justify-center">
      <img class="shrink-0" [src]="imageSrc()" />
    </div>
    <div class="flex flex-col justify-center gap-1 overflow-hidden">
      <h4 class="break-words text-xl font-bold text-neutral-body">
        {{ title() }}
      </h4>
      <p class="text-s break-words font-normal text-neutral-body">
        {{ description() }}
      </p>
    </div>
  </div>
}
