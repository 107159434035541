<div
  class="flex h-full max-w-xs flex-col items-center justify-between gap-6 rounded-lg border border-solid p-6 border-neutral-default"
>
  <div class="flex w-full flex-col items-center gap-6">
    <cca-label-badge
      [flavor]="tagColor()"
      [labelName]="tagName()"
    ></cca-label-badge>
    <p class="text-center text-base text-neutral-body">
      {{ description() }}
    </p>
  </div>
  @if (buttonType() === 'stroked') {
    <button
      mat-stroked-button
      [type]="buttonFormId() ? 'submit' : 'button'"
      [attr.form]="buttonFormId()"
      color="primary"
      (click)="buttonClick.emit()"
    >
      <cca-icon [icon]="buttonIcon()" class="me-2"></cca-icon>
      {{ buttonText() }}
    </button>
  } @else {
    <button
      mat-flat-button
      [type]="buttonFormId() ? 'submit' : 'button'"
      [attr.]
      color="primary"
      (click)="buttonClick.emit()"
    >
      <cca-icon [icon]="buttonIcon()" class="me-2"></cca-icon>
      {{ buttonText() }}
    </button>
  }
</div>
