import {
  Pipe,
  type PipeTransform,
  type OnDestroy,
  Injectable,
  inject,
} from '@angular/core';
import { BaseLocalePipe } from '@jsverse/transloco-locale';
import { type Subscription } from 'rxjs';
import { NumberPipeFormatterCache } from './number.pipe';
import { normalizeNumber } from './normalize-number';

// INTL, api is missing localised currencies for certain locales
// for example PLN when formatting this for en-GB it shows as PLN instead of zł
// this map holds a record for overwrites when requesting a certain currencySymbol it be formatted under another locale
const overwriteMap: Record<string, string> = {
  PLN: 'pl-PL',
};

@Injectable({
  providedIn: 'root',
})
class CurrencyPipeFormatterCache {
  private formatterCache = new Map<string, Map<string, Intl.NumberFormat>>();

  resolveFormatter(locale: string, currency: string) {
    const currencyFormatterMap = this.resolveLocaleMap(locale);

    // check if a formatter exists within the currencyFormatterMap
    // create a new one if it does not
    if (!currencyFormatterMap.has(currency)) {
      return this.createAndStoreFormatter(
        locale,
        currency,
        currencyFormatterMap,
      );
    }

    // return the formatter from the map
    return currencyFormatterMap.get(currency) as Intl.NumberFormat;
  }

  private resolveLocaleMap(locale: string): Map<string, Intl.NumberFormat> {
    // when the cache does not have a map for the requested locale create one
    // and set it in the cache
    if (!this.formatterCache.has(locale)) {
      return this.createAndStoreLocaleMap(locale);
    }

    // return the currencyFormatMap value, we can be sure it does exist since above code would set it.
    return this.formatterCache.get(locale) as Map<string, Intl.NumberFormat>;
  }

  private createAndStoreLocaleMap(locale: string) {
    // create new map for storing formatter for locale
    const newLocaleMap = new Map<string, Intl.NumberFormat>();

    // save the map within the formatterMapCache
    this.formatterCache.set(locale, newLocaleMap);

    return newLocaleMap;
  }

  private createAndStoreFormatter(
    locale: string,
    currency: string,
    currencyFormatterMap: Map<string, Intl.NumberFormat>,
  ) {
    // create new number currency formatter
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });

    // save the formatter in the currencyFormatterMap
    currencyFormatterMap.set(currency, formatter);

    // return reference to the formatter
    return formatter;
  }
}

@Pipe({
  name: 'currency',
  pure: false,
})
export class CdkCurrencyPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  formatterCache = inject(CurrencyPipeFormatterCache);
  formatterCacheNumber = inject(NumberPipeFormatterCache);
  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(
    value: number | null | undefined,
    currency: string | null | undefined,
  ): string {
    if (typeof value !== 'number') {
      return '';
    }

    const normalizedValue = normalizeNumber(value);

    if (currency) {
      // resolve a formatter
      const formatter = this.formatterCache.resolveFormatter(
        this.localeService.getLocale(),
        currency,
      );

      return this.format(currency, formatter, normalizedValue);
    }

    // backup use a formatter to format number only
    const formatter = this.formatterCacheNumber.resolveFormatter(
      this.localeService.getLocale(),
    );

    return formatter.format(normalizedValue);
  }

  format(
    currency: string,
    formatter: Intl.NumberFormat,
    value: number | bigint | Intl.StringNumericLiteral,
  ) {
    // check for locale overwrites for currency
    const overwrite = overwriteMap[currency];
    if (overwrite) {
      // format by using the overwrite locale for this currency
      // but using the normal number rules for current locale
      // eg we format twice for each locale and replace the currency with the currency from the overwrite
      const symbolFormatter = this.formatterCache.resolveFormatter(
        overwrite,
        currency,
      );
      const overwrittenCurrencySymbol = symbolFormatter
        .formatToParts(value)
        .find((p) => p.type === 'currency');
      if (overwrittenCurrencySymbol) {
        const localizedParts = formatter.formatToParts(value);
        const index = localizedParts.findIndex((p) => p.type === 'currency');
        localizedParts[index] = { ...overwrittenCurrencySymbol };
        return localizedParts.reduce((acc, cur) => (acc += cur.value), '');
      }
    }

    // fallback to current locale formatting
    return formatter.format(value);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
  }
}

@Pipe({
  name: 'currencySymbol',
  pure: false,
})
export class CdkCurrencySymbolPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(CurrencyPipeFormatterCache);

  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(currency: string | null | undefined) {
    if (currency) {
      // resolve a formatter
      const formatter = this.formatterCache.resolveFormatter(
        // check if there is a overwrite to use a specific locale, else use the current one
        // this is needed since not every locale has the correct symbol for a certain currency
        overwriteMap[currency] ?? this.localeService.getLocale(),
        currency,
      );

      // use formatter to format a number to parts with value 0
      // this creates a array of parts with { type: 'currency' | 'integer' | 'decimal' | 'fraction'}
      // we are interested in the currency part of this, this will hold the symbol for the currency
      return formatter.formatToParts(0).find((part) => part.type === 'currency')
        ?.value;
    }
    return '';
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
  }
}
