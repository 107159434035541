import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { type CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'cca-icon-label-group',
  imports: [IconComponent],
  templateUrl: './icon-label-group.component.html',
  styleUrls: ['./icon-label-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelGroupComponent {
  readonly firstIcon = input.required<CtrlChainIcons>();
  readonly firstLabel = input.required<string>();
  readonly secondIcon = input.required<CtrlChainIcons>();
  readonly secondLabel = input.required<string>();
}
