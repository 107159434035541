import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CdkDatePipe } from '@cca-common/cdk';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { type AuditLogItemViewModel } from '@cca-infra/common';
import { RichTextViewerComponent } from '../rich-text-viewer';

export type EventLogColumns =
  | 'objectName'
  | 'oldValue'
  | 'newValue'
  | 'translatedIdentifier'
  | 'changedBy'
  | 'auditTimeStamp';

@Component({
  imports: [
    TranslocoModule,
    MatTableModule,
    CdkDatePipe,
    RichTextViewerComponent,
  ],
  selector: 'cca-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('eventLog')],
})
export class EventLogComponent {
  readonly dataSource = input<MatTableDataSource<AuditLogItemViewModel>>(
    new MatTableDataSource<AuditLogItemViewModel>(),
  );
  readonly displayedColumns = input.required<EventLogColumns[]>();

  containsHTML(input: string): boolean {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(input);
  }
}
