import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

export enum Mode {
  Light,
  Dark,
}
@Component({
  selector: 'cca-avatar',
  imports: [NgClass],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  Mode = Mode;
  readonly url = input<string | null>();
  readonly initials = input<string>();
  readonly mode = input<Mode>(Mode.Light);
  readonly size = input.required<number>();
}
