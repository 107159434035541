import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { CdkDatePipe, type DatePipeInput } from '@cca-common/cdk';
import { type Locale } from '@jsverse/transloco-locale';
import { type Zone } from 'luxon';

@Component({
  imports: [CdkDatePipe],
  selector: 'cca-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDateCellComponent {
  readonly format = input('shortDate');
  readonly date = input.required<DatePipeInput>();

  readonly locale = input<Locale | null>();
  readonly timezone = input<string | Zone | null>();
}
