import { Directive, ElementRef, type OnInit, inject } from '@angular/core';

@Directive({
  selector: '[ccaAutofocus]',
})
export class AutofocusDirective implements OnInit {
  private el = inject(ElementRef);

  ngOnInit() {
    this.el.nativeElement.focus();
  }
}
