import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';

export type apiKey = {
  userGroupId: string;
  webHookUrl: string;
  apiKey: string;
};

@Injectable({
  providedIn: 'root',
})
export class ApiAndWebhookService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getApiEnterpriseKeyAndWebhook(enterpriseId: id) {
    return this.http.get<apiKey>(`${this.url}detail/api/${enterpriseId}`);
  }

  updateWebhookUrl(userGroupId: id, webHookUrl: string) {
    return this.http.post<void>(`${this.url}update/api`, {
      userGroupId,
      webHookUrl,
    });
  }
}
