<ng-container>
  <header>
    <div class="mr-auto flex flex-col gap-1">
      <div class="mr-auto flex gap-4">
        <h1 class="self-center text-xl font-medium">
          <span class="text-neutral-body">
            {{ titlePart1() }}
          </span>
          <span class="text-neutral-subtitle">
            {{ titlePart2() }}
          </span>
        </h1>

        @let badgeText = this.badgeText();
        @if (badgeText) {
          <cca-status-badge class="self-center" [flavor]="badgeFlavor()">
            {{ badgeText }}
          </cca-status-badge>
        }
      </div>

      @let description = this.description();
      @if (description) {
        <div class="flex w-full text-neutral-subtitle">{{ description }}</div>
      }
    </div>
    @if (statusCountBarData()?.length) {
      <div>
        <cca-statuses-count [statuses]="statusCountBarData()">
        </cca-statuses-count>
      </div>
    }
    <div class="flex gap-3">
      @let button1Label = this.button1Label();
      @if (button1Label) {
        <button
          type="button"
          mat-stroked-button
          [disabled]="button1Disabled()"
          color="accent"
          (click)="button1Clicked.emit()"
        >
          {{ button1Label }}
        </button>
      }

      @let button2Label = this.button2Label();
      @if (button2Label) {
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="button2Disabled()"
          (click)="button2Clicked.emit()"
        >
          {{ button2Label }}
        </button>
      }

      @let button3Label = this.button3Label();
      @if (button3Label) {
        <button
          type="submit"
          mat-stroked-button
          color="accent"
          [disabled]="button3Disabled()"
          (click)="button3Clicked.emit()"
        >
          {{ button3Label }}
        </button>
      }

      @let button4Label = this.button4Label();
      @if (button4Label) {
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="button4Disabled()"
          (click)="button4Clicked.emit()"
        >
          {{ button4Label }}
        </button>
      }
    </div>
  </header>
</ng-container>
