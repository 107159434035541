<div class="flex flex-row gap-4">
  @for (status of statuses(); track status) {
    <span class="text-lg font-normal text-neutral-caption">
      {{ status.text }}
      <span class="text-xl font-bold" [ngClass]="status.flavor">
        {{ status.number }}
      </span>
    </span>
  }
</div>
