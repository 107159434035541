/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  Directive,
  effect,
  ElementRef,
  input,
  Renderer2,
  inject,
} from '@angular/core';

@Directive({
  selector: '[ccaFullscreen]',
})
export class CdkFullscreenDirective {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  mode = input.required<'fullscreen' | 'fullPage'>();
  isFull = input<boolean>(false);

  constructor() {
    effect(() => {
      const isFull = this.isFull();
      const mode = this.mode();

      switch (mode) {
        case 'fullscreen':
          this._toggleFullscreen(isFull);
          break;
        case 'fullPage':
          this._toggleFullPage(isFull);
          break;
      }
    });
  }

  private _toggleFullscreen(open: boolean) {
    if (open) {
      if (this.el.nativeElement.requestFullscreen) {
        this.el.nativeElement.requestFullscreen();
      } else if (this.el.nativeElement.mozRequestFullScreen) {
        /* Firefox */
        this.el.nativeElement.mozRequestFullScreen();
      } else if (this.el.nativeElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.el.nativeElement.webkitRequestFullscreen();
      } else if (this.el.nativeElement.msRequestFullscreen) {
        /* IE/Edge */
        this.el.nativeElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE/Edge */
        (document as any).msExitFullscreen();
      }
    }
  }

  private _toggleFullPage(open: boolean) {
    if (open) this.renderer.addClass(this.el.nativeElement, 'full-page');
    else this.renderer.removeClass(this.el.nativeElement, 'full-page');
  }
}
