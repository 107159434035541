import { NgTemplateOutlet } from '@angular/common';
import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  imports: [NgTemplateOutlet, MatTooltipModule],
  selector: 'cca-text-cell',
  templateUrl: './text-cell.component.html',
  styleUrls: ['./text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextCellComponent {
  readonly text = input('');
  readonly showTooltip = input(false);
}
