<div class="relative flex flex-col items-center gap-1 pt-1">
  <div class="flex w-40 items-center">
    <span
      class="flex h-4 w-4 shrink-0 rounded-full border-2 border-solid surface-neutral-light border-neutral-dark"
    ></span>
    <span
      class="flex h-1 w-full items-center justify-center surface-neutral-darker"
    >
      @if (numberOfStops() > 2) {
        <span
          class="p-1 text-sm font-medium surface-neutral-light text-brand-default"
          >+{{ numberOfStops() - 2 }} {{ stopsText() }}</span
        >
      }
    </span>
    <span
      class="flex h-4 w-4 shrink-0 rounded-full border-2 border-solid surface-neutral-light border-neutral-dark"
    ></span>
  </div>
  <span class="text-sm font-medium info-text">
    {{ text() }}
  </span>
</div>
