import { Injectable } from '@angular/core';
import { of, catchError } from 'rxjs';
import { BaseApiService } from '@cca-infra/core';
import {
  type CreateCspErrorLogRequest,
  type CreateGeneralErrorLogRequest,
  type CreateHttpErrorLogRequest,
  type GeneralErrorLog,
} from '../model';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService extends BaseApiService {
  constructor() {
    super('logging.v1.logging');
  }

  reportError(errorLog: CreateGeneralErrorLogRequest) {
    return this.http
      .post(`${this.url}general`, {
        ...errorLog,
        url: location.href,
        userAgent: this.userAgent,
        clientDateTime: this.clientDateTime,
      })
      .pipe(catchError(() => of()));
  }

  getErrors() {
    return this.http.get<GeneralErrorLog[]>(`${this.url}get-general-errors`);
  }

  reportHttpError(errorLog: CreateHttpErrorLogRequest) {
    return this.http
      .post(`${this.url}http`, {
        ...errorLog,
        url: location.href,
        userAgent: this.userAgent,
        clientDateTime: this.clientDateTime,
      })
      .pipe(catchError(() => of()));
  }

  reportCspError(errorLog: CreateCspErrorLogRequest) {
    return this.http
      .post(`${this.url}csp`, {
        ...errorLog,
        url: location.href,
        userAgent: this.userAgent,
        clientDateTime: this.clientDateTime,
      })
      .pipe(catchError(() => of()));
  }

  private get userAgent() {
    if (window && window.navigator) {
      return window.navigator.userAgent;
    }
    return null;
  }

  private get clientDateTime() {
    return DateTime.now().toMillis();
  }
}
