<div
  class="flex w-full items-start justify-between text-neutral-body"
  [ngClass]="{
    'actionHeader gap-3': flavor() === Flavor.Primary,
    'userCard gap-2': flavor() === Flavor.Secondary,
  }"
>
  <div
    class="flex items-center"
    [ngClass]="{
      'gap-3': flavor() === Flavor.Primary,
      'gap-2': flavor() === Flavor.Secondary,
    }"
  >
    <div
      class="grid shrink-0 place-items-center place-self-center rounded-full text-2xl"
      [ngClass]="{
        'h-16 w-16 info-surface-lighter info-text': flavor() === Flavor.Primary,
        'h-10 w-10 border bg-transparent border-brand-default text-brand-default':
          flavor() === Flavor.Secondary,
      }"
    >
      @let icon = this.icon();
      @if (icon) {
        <cca-icon [icon]="icon"></cca-icon>
      }
    </div>

    <div class="title flex flex-col justify-center">
      <h2 [class.text-base]="flavor() === Flavor.Secondary">
        @if (stopNumber) {
          <span class="me-1">
            {{ translations().stop }} #{{ stopNumber }}:
          </span>
        }
        {{
          (action()?.iterationNumber ?? 0) > 1
            ? action()?.reDoActionLabel
            : action()?.label
        }}
      </h2>
      <span
        class="text-wrap text-base text-neutral-subtitle"
        [ngClass]="{
          'text-sm': flavor() === Flavor.Secondary,
        }"
        >{{ action()?.shortDescription }}</span
      >
    </div>
  </div>

  <div class="content flex max-w-[50%] flex-row-reverse items-center gap-3">
    <ng-content></ng-content>
  </div>
</div>
