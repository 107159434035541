import { type OnDestroy, Pipe, type PipeTransform } from '@angular/core';
import { type Locale, BaseLocalePipe } from '@jsverse/transloco-locale';

@Pipe({
  name: 'unitFormat',
  pure: false,
})
export class CdkUnitFormatPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  transform(value: number, unit: string, locale?: Locale | undefined): string {
    return Intl.NumberFormat(this.getLocale(locale), {
      style: 'unit',
      unit: unit,
      unitDisplay: 'short',
    }).format(value);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
