import { type CCADateTime, type id } from '@cca-infra/common';

export type Iteration = {
  pickup: {
    earliestDate?: CCADateTime | undefined;
    latestDate?: CCADateTime | undefined;
  };
  delivery: {
    earliestDate?: CCADateTime | undefined;
    latestDate?: CCADateTime | undefined;
  };
  vehicleAmount: number;
  price: number | null | undefined;
  timeOptionId?: id | null | undefined;
  shipperReference?: string | null;
  pickupReference?: string | null;
  deliveryReference?: string | null;
  currencyType?: string | null | undefined;
};

export enum ChargeLineKeys {
  offsetCharge = 'offsetChargeKey',
}
