/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  type DoCheck,
  NgModule,
  type OnChanges,
  type OnDestroy,
  TemplateRef,
  ViewContainerRef,
  inject,
  input,
} from '@angular/core';

@Directive({
  selector: '[ccaContainer]',
})
export class ContainerDirective implements OnChanges {
  state: unknown;
  readonly ccaContainer = input<any>();
  readonly ccaContainerSwitch = input<(data?: any) => any>(() => null);

  ngOnChanges() {
    this.state = this.ccaContainerSwitch()(this.ccaContainer());
  }
}

@Directive({
  selector: '[ccaContainerState], [cca-container-state]',
  exportAs: 'ccaContainerState',
})
export class CdkContainerStateDirective implements DoCheck, OnDestroy {
  private viewContainerRef = inject(ViewContainerRef);
  private templateRef = inject<TemplateRef<unknown>>(TemplateRef);
  private container = inject(ContainerDirective, {
    optional: true,
    host: true,
  });

  private created = false;
  readonly ccaContainerState = input<any>(null);

  create(): void {
    if (!this.created) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.created = true;
    }
  }

  clear(): void {
    if (this.created) {
      this.viewContainerRef.clear();
      this.created = false;
    }
  }

  ngDoCheck() {
    if (this.ccaContainerState() === this.container?.state) {
      this.create();
    } else {
      this.clear();
    }
  }

  ngOnDestroy() {
    this.clear();
  }
}

@NgModule({
  imports: [ContainerDirective, CdkContainerStateDirective],
  exports: [ContainerDirective, CdkContainerStateDirective],
})
export class CdkContainerDirectiveModule {}
