<div class="flex flex-col gap-4">
  @if (showTitle()) {
    <div class="flex justify-between border-b pb-4 border-neutral-default">
      <div class="flex flex-col gap-1 text-neutral-body">
        <h2 class="text-lg font-bold">{{ enterpriseName }}</h2>
        @if (!isTreeView) {
          <h3 class="text-sm font-normal">
            {{ subTitleLabel() }}
          </h3>
        }
        @if (isTreeView) {
          <h3 class="text-sm font-normal">
            {{ subTitleTreeviewLabel() }}
          </h3>
        }
      </div>
      <div class="flex gap-4">
        <cca-search-field
          class="w-full"
          (searchChange)="onSearchChange($event)"
          [searchLabel]="searchLabel()"
        />
        @if (displayInTree()) {
          <button
            type="button"
            color="primary"
            [disabled]="this.searching"
            mat-mini-fab
            (click)="switchView()"
          >
            <cca-icon [icon]="isTreeView ? 'list' : 'group'"></cca-icon>
          </button>
        }
      </div>
    </div>
  } @else {
    <div class="flex justify-between">
      <cca-search-field
        class="w-full"
        (searchChange)="onSearchChange($event)"
        [searchLabel]="searchLabel()"
      />
      @if (displayInTree()) {
        <button
          type="button"
          color="primary"
          [disabled]="this.searching"
          mat-mini-fab
          (click)="switchView()"
        >
          <cca-icon [icon]="isTreeView ? 'list' : 'group'"></cca-icon>
        </button>
      }
    </div>
  }

  @if (showValidationError() && !selectedSubGroupId()) {
    <cca-info-banner
      flavor="warning"
      [description]="subgroupValidationErrorLabel()"
      class="flex w-max"
    ></cca-info-banner>
  }
  @if (searchText) {
    <p class="text-lg font-bold text-neutral-body">
      {{ searchResultsLabel() }}
      &nbsp;“{{ searchText }}”
    </p>
  }
  @if (isTreeView ? treeViewData : dataSource.filteredData.length) {
    <ng-template
      [ngTemplateOutlet]="isTreeView ? treeview : table"
    ></ng-template>
  } @else {
    <p class="noResult">{{ noDataLabel() }} {{ this.dataSource.filter }}</p>
  }
  <ng-template #treeview>
    <cca-tree-view
      [groupData]="treeViewData"
      [selectedNode]="selectedSubGroupId() ?? null"
      (nodeSelected)="radioChanged($event)"
    >
      <ng-template #tailTemplate let-node>
        <div class="flex items-center">
          @if (!node?.anyUsersInGroup) {
            <cca-icon
              [matTooltip]="tooltipSubgroupHasNoUsersLabel()"
              matTooltipPosition="above"
              [icon]="'user-slash'"
              class="ml-2 text-neutral-disabled"
            ></cca-icon>
          }
        </div>
      </ng-template>
    </cca-tree-view>
  </ng-template>
  <ng-template #table>
    <div class="flex flex-col gap-4">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <td
            mat-cell
            *matCellDef="let element"
            class="cursor-pointer !py-1"
            (click)="
              element.isSelectable
                ? setSelectedSubGroupId(element.groupId)
                : null
            "
          >
            <mat-radio-button
              [disabled]="!element.isSelectable"
              color="primary"
              (change)="radioChanged($event)"
              [checked]="selectedSubGroupId() === element.groupId"
              class="w-full"
              [value]="element.groupId"
            >
              <span class="cursor-pointer text-base font-medium">{{
                element.groupName
              }}</span>
            </mat-radio-button>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-divider />
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="10"
      ></mat-paginator>
    </div>
  </ng-template>
</div>
