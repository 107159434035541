import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { type CtrlChainIcons } from '@cca-common/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../icon';
import { UiLabelBadgeComponent } from '../../badges';
import { CountryFlagComponent } from '../../country-flag';

export type BadgeInfo = {
  label: string;
  color: string;
};

@Component({
  selector: 'cca-overview-info-row',
  templateUrl: './overview-info-row.component.html',
  styleUrls: ['./overview-info-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    IconComponent,
    MatTooltipModule,
    UiLabelBadgeComponent,
    CountryFlagComponent,
  ],
})
export class OverviewInfoRowComponent {
  readonly startHeaderTitle = input<string>();
  readonly startHeaderSubtitle = input<string>();
  readonly startHeaderSubtitleFontSize = input('text-sm');
  readonly startHeaderSubtitleFontWeight = input('font-medium');
  readonly startHeaderSubtitleIcon = input<CtrlChainIcons>();
  readonly badges = input<BadgeInfo[]>();
  readonly canHaveTailLift = input(false);
  readonly canHaveTailLiftTitle = input<string>();
  readonly canHaveTailLiftYes = input<string>();
  readonly startHeaderText = input<string | null>();
  readonly startHeaderTextFontWeight = input<string | undefined>('font-normal');
  readonly startHeaderIcon = input<CtrlChainIcons>();
  readonly startSecondSubtitle = input<string>();
  readonly startSecondText = input<string | null>();
  readonly middleHeaderTitle = input<string | undefined>('');
  readonly middleHeaderSubtitle = input<string | null>();
  readonly middleHeaderText = input<string | null>();
  readonly middleHeaderFlagUrl = input<string | null>();
  readonly middleHeaderSubtitleFontSize = input('text-sm');
  readonly middleHeaderSubtitleFontWeight = input('font-medium');
  readonly endHeaderTitle = input<string | undefined>('');
  readonly endHeaderSubtitle = input<string | null>();
  readonly endHeaderText = input<string | null>();
  readonly infoLink = input<string>();
  readonly middleInfoTooltipText = input<string | null>();
  readonly endInfoTooltipText = input<string | null>();
}
