import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';

export type apiKeyModel = {
  apiKey: string;
};

export type apiKeyEnterprise = {
  userGroupId: string;
  webHookUrl: string;
  apiKey: string;
};

@Injectable({
  providedIn: 'root',
})
export class ApiKeyService extends BaseApiService {
  constructor() {
    super(`user.v1.apiKey`);
  }

  createApiKeyEnterprise(enterpriseId: id) {
    return this.http.post<apiKeyModel>(`${this.url}create`, {
      enterpriseGroupId: enterpriseId,
    });
  }
}
