import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-accordion',
  imports: [IconComponent, MatButtonModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  readonly accordionHeader = input.required<string>();
  readonly accordionDeleteButton = input.required<string>();
  readonly showErrorState = input(false);
  invalidText = input.required<string>();

  readonly removeItem = output();

  accordionOpened = signal(true);

  toggleAccordion() {
    this.accordionOpened.set(!this.accordionOpened());
  }
}
