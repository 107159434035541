import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output,
  input,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { IconComponent } from '../icon';

interface GeneralBookingDialogData {
  description: string;
  label: string;
  cancelButton: string;
  hasTwoButtons: boolean;
}

@Component({
  selector: 'cca-general-booking-dialog',
  imports: [
    MatButtonModule,
    IconComponent,
    MatFormFieldModule,
    MatDialogModule,
    TranslocoModule,
  ],
  templateUrl: './general-booking-dialog.component.html',
  styleUrls: ['./general-booking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralBookingDialogComponent {
  private _data = inject<GeneralBookingDialogData>(MAT_DIALOG_DATA, {
    optional: true,
  });

  readonly label = input<string | undefined>(this._data?.label);
  readonly description = input<string | undefined>(this._data?.description);
  readonly confirmButton = input<string>();
  readonly cancelButton = input<string | undefined>(this._data?.cancelButton);
  readonly hasTwoButtons = input(this._data?.hasTwoButtons ?? true);

  readonly confirmButtonClick = output();
  readonly cancelButtonClick = output();
}
