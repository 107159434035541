import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';

export type InformationTileTranslations = {
  title: string;
  description: string;
};

@Component({
  selector: 'cca-information-tile',
  imports: [],
  templateUrl: './information-tile.component.html',
  styleUrls: ['./information-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationTileComponent {
  readonly translations = input.required<
    InformationTileTranslations | undefined
  >();
  readonly imageSrc = input.required<string>();
  readonly active = input(false);
  readonly selected = output<void>();
}
