<ol class="flex w-full flex-wrap gap-4 text-base font-normal text-neutral-body">
  @for (step of steps(); track step; let index = $index; let last = $last) {
    <li class="flex min-w-32 flex-grow items-center gap-2">
      <span
        class="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border"
        [ngClass]="
          index <= activeIndex()
            ? 'text-neutral-invert surface-brand-default border-brand-default'
            : 'border-neutral-dark'
        "
      >
        @if (index <= activeIndex()) {
          <cca-icon icon="check" class="text-sm"></cca-icon>
        }
      </span>

      {{ step }}

      @if (!last) {
        <div
          class="h-[0.125rem] flex-1"
          [ngClass]="
            index <= activeIndex()
              ? 'surface-brand-default'
              : 'neutral-surface-dark'
          "
        ></div>
      }
    </li>
  }
</ol>
