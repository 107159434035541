import { Component, input, computed } from '@angular/core';
import { NgClass } from '@angular/common';
import { type StatusBadgeFlavor } from '@cca-common/core';

@Component({
  imports: [NgClass],
  selector: 'cca-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class UiStatusBadgeComponent {
  readonly flavor = input<StatusBadgeFlavor | undefined>();

  protected readonly currentFlavor = computed(() => {
    return this.flavor() ?? 'primary';
  });
}
