import { Injectable } from '@angular/core';
import { type id } from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  type ConfirmEntityRouteRequest,
  type EntityRouteViewModel,
  type EntityType,
  type RouteOptionsViewModel,
  type RouteViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class RoutingsService extends BaseApiService {
  constructor() {
    super(`location.v1.routing`);
  }

  getEntityRouteDetail(entityId: id, entityType: EntityType) {
    return this.http.get<EntityRouteViewModel>(
      `${this.url}get-entity-route/${entityId}/${entityType}`,
    );
  }

  getRouteAlternatives(
    entityId: id,
    entityType: EntityType,
    options: RouteOptionsViewModel,
  ) {
    return this.http.post<RouteViewModel>(
      `${this.url}get-route-alternatives/${entityId}/${entityType}`,
      options,
    );
  }

  confirmEntityRoute(body: ConfirmEntityRouteRequest) {
    return this.http.post<boolean>(`${this.url}confirm-entity-route`, body);
  }
}
