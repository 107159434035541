import {
  Injectable,
  type OnDestroy,
  Pipe,
  type PipeTransform,
  inject,
} from '@angular/core';
import { DateTime, type Zone } from 'luxon';
import { BaseLocalePipe } from '@jsverse/transloco-locale';
import { type DatePipeInput } from './date.pipe';
import { type Observable, type Subscription, timer } from 'rxjs';
import { isCCADateTime } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class DateRelativePipeFormatterCache {
  private formatterCache = new Map<string, Intl.RelativeTimeFormat>();

  resolveFormatter(locale: string) {
    // check if a formatter exists within the currencyFormatterMap
    // create a new one if it does not
    if (!this.formatterCache.has(locale)) {
      return this.createAndStoreFormatter(locale);
    }

    // return the formatter from the map
    return this.formatterCache.get(locale) as Intl.RelativeTimeFormat;
  }

  private createAndStoreFormatter(locale: string) {
    // create new number formatter
    const formatter = new Intl.RelativeTimeFormat(locale, {
      localeMatcher: 'best fit',
      numeric: 'auto',
      style: 'long',
    });

    // save the formatter
    this.formatterCache.set(locale, formatter);

    // return reference to the formatter
    return formatter;
  }
}

@Pipe({
  name: 'ccaDateRelative',
  pure: false,
})
export class CdkDateRelativePipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(DateRelativePipeFormatterCache);

  localeSubscription: Subscription | null;
  timerSubscription: Subscription | null = null;
  timer: Observable<number> | null = null;

  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  clearTimer() {
    this.timer = null;
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = null;
  }

  setTimer(period: Intl.RelativeTimeFormatUnit) {
    switch (period) {
      // schedule for each second
      case 'second':
      case 'seconds':
        {
          this.timer = timer(
            DateTime.now()
              .set({
                millisecond: 0,
              })
              .plus({
                second: 1,
              })
              .toMillis() - DateTime.now().toMillis(),
          );
        }
        break;

      // else schedule every minute
      default:
        {
          this.timer = timer(
            DateTime.now()
              .set({
                millisecond: 0,
                second: 0,
              })
              .plus({
                minute: 1,
              })
              .toMillis() - DateTime.now().toMillis(),
          );
        }
        break;
    }

    if (this.timer) {
      this.timerSubscription = this.timer?.subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  transform(value: DatePipeInput, timezone?: string | Zone | null | undefined) {
    timezone = timezone ?? undefined;
    this.clearTimer();
    return this.formatDate(value, timezone);
  }

  formatDate(
    value: DatePipeInput,
    timezone?: string | Zone | null | undefined,
  ) {
    if (value) {
      let dateTime: DateTime | undefined;
      if (isCCADateTime(value)) {
        const usedTimeZone = timezone ?? value.timeZoneId;
        dateTime = DateTime.fromMillis(value.milliSeconds, {
          zone: usedTimeZone ?? undefined,
        });
      } else if (DateTime.isDateTime(value)) {
        dateTime = DateTime.fromMillis(value.valueOf(), {
          zone: timezone ?? value.zone,
        });
      } else if (typeof value === 'number') {
        dateTime = DateTime.fromMillis(value.valueOf(), {
          zone: timezone ?? undefined,
        });
      } else if (typeof value === 'string') {
        dateTime = DateTime.fromISO(value.valueOf(), {
          zone: timezone ?? undefined,
        });
      } else if (value instanceof Date) {
        dateTime = DateTime.fromJSDate(value, {
          zone: timezone ?? undefined,
        });
      }

      if (dateTime) {
        const { period, value } = calculateTopPeriodAndValue(
          dateTime as DateTime,
        );
        const formatter = this.formatterCache.resolveFormatter(
          this.localeService.getLocale(),
        );
        this.setTimer(period);
        return formatter.format(Math.floor(value), period);
      }
    }

    return '';
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
    this.clearTimer();
  }
}

function calculateTopPeriodAndValue(myDate: DateTime): {
  period: Intl.RelativeTimeFormatUnit;
  value: number;
} {
  const duration = myDate.diffNow([
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);
  if (duration.years !== 0) {
    return { period: 'years', value: duration.years };
  }
  if (duration.months !== 0) {
    return { period: 'months', value: duration.months };
  }
  if (duration.days !== 0) {
    return { period: 'days', value: duration.days };
  }
  if (duration.hours !== 0) {
    return { period: 'hours', value: duration.hours };
  }
  if (duration.minutes !== 0) {
    return { period: 'minutes', value: duration.minutes };
  }
  return { period: 'second', value: duration.seconds };
}
