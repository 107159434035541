import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type Filter,
  type FilterInfo,
  type AuditLogItemViewModel,
  type id,
  type FilterChoiceOption,
} from '@cca-infra/common';
import {
  type ManageOperatorRequest,
  type OrderRequestViewModel,
  type RequestContactViewModel,
  type RequestPaginationItemViewModel,
  type ShippingRequirementViewModel,
  type UpdateRequestCommentRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class RequestService extends BaseApiService {
  constructor() {
    super(`request.v1.request`);
  }

  requestPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<RequestPaginationItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  requestExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  orderPaginationCount(filters: Filter[]): Observable<number> {
    return this.http.post<number>(`${this.url}paginated-count`, {
      filterDefinitions: filters,
    });
  }

  requestPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  updateComments(requestId: id, comments: UpdateRequestCommentRequest) {
    return this.http.post<void>(`${this.url}update-comments`, {
      ...comments,
      requestId: requestId,
    });
  }

  updateOperator(request: ManageOperatorRequest) {
    return this.http.post(`${this.url}manage-operator`, request);
  }

  getOrders(requestId: id) {
    return this.http.get<OrderRequestViewModel[]>(
      `${this.url}get-orders/${requestId}`,
    );
  }

  getContacts(requestId: id) {
    return this.http.get<RequestContactViewModel[]>(
      `${this.url}contacts/${requestId}`,
    );
  }

  getEventLog(requestId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${requestId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  syncFromThirdParty(requestId: id) {
    return this.http.get<boolean>(
      `${this.url}sync-from-third-party/${requestId}`,
    );
  }

  getAllShippingRequirements() {
    return this.http.get<ShippingRequirementViewModel[]>(
      `${this.url}get-all-shipping-requirements/`,
    );
  }
}
