import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { CdkTransformPipe } from '@cca-common/cdk';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkDatePipe, CdkDateRelativePipe } from '@cca-common/cdk';
import { type DateObjectUnits, DateTime, type Zone } from 'luxon';
import { type Locale } from '@jsverse/transloco-locale';
import { type CCADateTime, convertToDateTime } from '@cca-infra/common';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { type DateTimeZoneViewModel } from '@cca-infra/order-management/v2';
import { IconComponent } from '../icon';

// used for input of start/end, and references to this same type.

export type PlanningInfoDate =
  | DateTimeZoneViewModel
  | CCADateTime
  | DateTime
  | Date
  | string
  | number
  | undefined
  | null;

export enum TimeConditionType {
  None = 'None',
  Between = 'Between',
  Exact = 'Exact',
  At = 'At',
  Before = 'Before',
  After = 'After',
  WithinXHours = 'WithinXHours',
}

export enum PlanningInfoLabel {
  Undefined = '',
  At = 'At',
  Before = 'Before',
  After = 'After',
  Between = 'Between',
}

@Component({
  imports: [
    NgClass,
    TranslocoModule,
    IconComponent,
    CdkTransformPipe,
    MatTooltipModule,
    CdkDatePipe,
    CdkDateRelativePipe,
  ],
  selector: 'cca-planning-info',
  templateUrl: './planning-info.component.html',
  styleUrls: ['./planning-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('bookingDetail')],
})
export class PlanningInfoComponent {
  readonly dateFormat = input('ff z');
  readonly timeFormat = input('t');
  readonly label = input<PlanningInfoLabel>(PlanningInfoLabel.At);
  readonly iconClass = input('text-neutral-title');
  readonly startDatetime = input<PlanningInfoDate>();
  readonly endDatetime = input<PlanningInfoDate>();
  readonly timeZone = input<string | Zone | undefined | null>(null);
  readonly unknownDateText = input<string | null>(null);
  readonly unknownTimeText = input<string | null>(null);
  readonly hideTooltipStartDate = input(false);
  readonly hideTooltipEndDate = input(false);
  readonly locale = input<Locale>();
  readonly hideTime = input(false);
  readonly showRemainingDays = input(false);

  PlanningInfoLabel = PlanningInfoLabel;

  diffInDays(
    startDate: PlanningInfoDate,
    endDate: PlanningInfoDate,
    abs = true,
  ): number | null {
    if (!startDate || !endDate) {
      return 0;
    }

    const startDatetime = convertToDateTime(startDate);
    const endDateTime = convertToDateTime(endDate);

    if (startDatetime && endDateTime) {
      const timeSetter: DateObjectUnits = {
        hour: 0,
        minute: 0,
        millisecond: 0,
      };

      // normalize the time such that the day will always be a round number 1,2,3 instead of 1.123456
      const difference = startDatetime
        .set({ ...timeSetter })
        .diff(endDateTime.set({ ...timeSetter }), 'day');

      if (abs) {
        // the difference can return a negative, taking the absolute value of it will make it a positive
        return Math.trunc(Math.abs(difference.days));
      } else {
        return Math.trunc(difference.days);
      }
    }

    return null;
  }

  get currentTimezone() {
    return new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
  }

  get showRemainingMessage() {
    return (
      this.showRemainingDays() &&
      this.remainingDaysNr &&
      this.remainingDaysNr <= 5 &&
      this.remainingDaysNr >= 0
    );
  }

  get remainingDaysNr() {
    const result = this.diffInDays(this.startDatetime(), DateTime.now(), false);
    if (this.startDatetime() && result) {
      return result;
    }
    return null;
  }
}
