<span
  class="rounded-full font-medium {{
    flavor()
  }} inline-flex items-center justify-center"
  [ngClass]="number() ? 'min-w-[0.9375rem]' : ''"
>
  @if (!number()) {
    <span class="block" [ngClass]="smallBadge() ? 'h-2 w-2' : 'h-3 w-3'">
    </span>
  }
  @if (number()) {
    <span [ngClass]="smallBadge() ? 'px-1 text-2xs' : 'px-2 py-1 text-xs'">
      {{ number() }}
    </span>
  }
</span>
