import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type UpdateSystemSettingsRequest } from '../model';

/**
 * TODO: remove following interface, swagger says endpoint doesnt return this but it is used in fe
 */
export interface RequestSettings {
  internalReminderHours: number;
  quotationReminderHours: number;
  quotationExpireHours: number;
}

@Injectable({
  providedIn: 'root',
})
export class RequestSystemSettingsService extends BaseApiService {
  constructor() {
    super(`request.v1.systemSettings`);
  }

  getSettings() {
    return this.http.get<RequestSettings>(`${this.url}get-settings`);
  }

  updateSettings(settings: UpdateSystemSettingsRequest) {
    return this.http.post(`${this.url}update-settings`, { ...settings });
  }
}
