import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'cca-information-card',
  imports: [],
  templateUrl: './information-card.component.html',
  styleUrls: ['./information-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationCardComponent {
  readonly title = input.required<string | undefined>();
  readonly description = input.required<string | undefined>();
  readonly vertical = input(false);
  readonly imageSrc = input.required<string>();
}
