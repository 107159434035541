import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'cca-yes-no-radio',
  imports: [MatRadioModule, ReactiveFormsModule],
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoRadioComponent {
  readonly questionLabel = input<string>();
  readonly button1Label = input<string>();
  readonly button2Label = input<string>();
  readonly disabled = input(false);
  readonly formCtrl = input<FormControl>(new FormControl());

  readonly selectionChanged = output<boolean>();
}
