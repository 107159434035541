import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { type CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-detail-item',
  imports: [NgClass, IconComponent],
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailItemComponent {
  readonly icon = input<CtrlChainIcons>('');
  readonly label = input<string>();
  readonly value = input<number | string>();
  readonly symbol = input<string>();
  readonly smallSize = input(false);
}
