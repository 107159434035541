import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type FilterChoiceOption,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type ExactGroupViewModel,
  type InvoiceDetailViewModel,
  type InvoicePartiesDetailViewModel,
  type InvoiceRowViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class InvoicingService extends BaseApiService {
  constructor() {
    super(`finance.v1.invoice`);
  }

  invoicingPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<InvoiceRowViewModel>> {
    return this.http.post<PaginationResponse<InvoiceRowViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  invoicingPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  createInvoice(
    userGroupId: id,
    creditInvoice: boolean,
    sendToExact: boolean,
    setIds: id[],
  ) {
    return this.http.post(`${this.url}create`, {
      userGroupId,
      creditInvoice,
      sendToExact: sendToExact,
      setIds,
    });
  }

  carrierGroupIsConnected(userGroupId: id) {
    return this.http.get(
      `${this.url}group-is-connected/carrier/${userGroupId}`,
    );
  }

  shipperGroupIsConnected(userGroupId: id) {
    return this.http.get(
      `${this.url}group-is-connected/shipper/${userGroupId}`,
    );
  }

  getGroupBySearchText(userGroupId: id, searchText: string) {
    return this.http.get<ExactGroupViewModel[]>(
      `${this.url}groups/${userGroupId}/${searchText}`,
    );
  }

  getExactGroupsByName(
    userGroupId: string,
    salesGroupId: string,
    divisionId: number,
    searchText: string,
  ) {
    return this.http.get<ExactGroupViewModel[]>(
      `${this.url}filtered-groups/${userGroupId}/${salesGroupId}/${divisionId}/${searchText}`,
    );
  }

  invoicingDetail(invoiceId: string): Observable<InvoiceDetailViewModel> {
    return this.http.get<InvoiceDetailViewModel>(
      `${this.url}detail/${invoiceId}`,
    );
  }

  cancelInvoice(invoiceId: string) {
    return this.http.post(`${this.url}cancel`, {
      invoiceId,
    });
  }

  finalizeInvoice(
    invoiceId: string,
    exactInvoiceNumber: string,
    sendPODEmail: boolean,
  ) {
    return this.http.post(`${this.url}finalize`, {
      invoiceId,
      exactInvoiceNumber,
      sendPODEmail,
    });
  }

  resendInvoicePods(
    invoiceId: string,
    exactInvoiceNumber: string,
    toEmailAddress: string,
  ) {
    return this.http.post(`${this.url}resend-pods`, {
      invoiceId,
      exactInvoiceNumber,
      toEmailAddress,
    });
  }

  getInvoicingParties(orderId: id) {
    return this.http.get<InvoicePartiesDetailViewModel[]>(
      `${this.url}get-multiple-invoicing-parties/${orderId}`,
    );
  }

  getCTMSInvoicingParties(orderId: id) {
    return this.http.get<InvoicePartiesDetailViewModel[]>(
      `${this.url}get-ctms-invoicing-parties/${orderId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }
}
