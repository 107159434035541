<div
  class="flex w-full flex-col items-center justify-center gap-1 rounded-xl p-4 text-center surface-neutral-default text-neutral-body"
>
  <cca-icon icon="info" class="text-2xl text-neutral-disabled"></cca-icon>
  <h4 class="text-base font-medium">{{ title() }}</h4>

  @let message = this.message();
  @if (message) {
    <p class="text-sm text-neutral-caption">
      {{ message }}
    </p>
  }
</div>
