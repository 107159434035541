import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-show-more-less',
  imports: [IconComponent],
  templateUrl: './show-more-less.component.html',
  styleUrl: './show-more-less.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreLessComponent {
  readonly showMoreLabel = input<string>();
  readonly showLessLabel = input<string>();
  readonly isShowMore = input(true);
  readonly isShowMoreChanged = output<void>();
}
