import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { type DateTime } from 'luxon';
import { CdkDatePipe } from '@cca-common/cdk';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { TimeIndicationType } from '@cca-infra/order-management/v2';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { type CCADateTime, convertToDateTime } from '@cca-infra/common';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-time-window',
  imports: [CdkDatePipe, IconComponent, TranslocoModule, NgxTippyModule],
  templateUrl: './time-window.component.html',
  styleUrls: ['./time-window.component.scss'],
  providers: [provideTranslocoScope('timeWindow')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeWindowComponent {
  private readonly transloco = inject(TranslocoService);
  readonly timeslotPreAgreed = input<boolean>();
  readonly timeslotRequired = input<boolean>();
  readonly timeOptionType = input<TimeIndicationType>();
  readonly label = input('');
  readonly isRange = input(false);
  readonly timezone = input('system');
  readonly showClockIcon = input(true);
  readonly showTimeOneLine = input(false);
  readonly timeWindowStart = input<
    CCADateTime | DateTime | number | null | undefined
  >();
  readonly _timeWindowStart = computed(() => {
    return convertToDateTime(this.timeWindowStart());
  });
  readonly timeWindowEnd = input<
    CCADateTime | DateTime | number | null | undefined
  >();
  readonly _timeWindowEnd = computed(() => {
    return convertToDateTime(this.timeWindowEnd());
  });

  TimeIndicationType = TimeIndicationType;

  protected timeOptionTranslation(timeOptionType: TimeIndicationType) {
    switch (timeOptionType) {
      case TimeIndicationType.Between:
        return this.transloco.translate(t('timeWindow.timeOption.between'));
      case TimeIndicationType.At:
        return this.transloco.translate(t('timeWindow.timeOption.at'));
      case TimeIndicationType.After:
        return this.transloco.translate(t('timeWindow.timeOption.after'));
      case TimeIndicationType.Before:
        return this.transloco.translate(t('timeWindow.timeOption.before'));
      case TimeIndicationType.Undefined:
      default:
        return this.transloco.translate(t('timeWindow.timeOption.unknown'));
    }
  }

  body = document.body;
}
