import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { type PaginationModalityViewModel } from '@cca-infra/request-management/v1';
import { shortAddress } from '@cca-common/core';
import { MatDividerModule } from '@angular/material/divider';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-carriers-per-modality-column',
  imports: [CommonModule, NgxTippyModule, IconComponent, MatDividerModule],
  templateUrl: './carriers-per-modality-column.component.html',
  styleUrls: ['./carriers-per-modality-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarriersPerModalityColumnComponent {
  supplierGroupsLabel = input('Supplier Groups');
  showMoreLabel = input('Show more');
  notAssignedText = input('Not Assigned');
  modalities = input<PaginationModalityViewModel[]>();

  shownModality = computed(() => {
    const modalities = this.modalities();
    this.modalitiesWithSuppliers = modalities?.filter(
      (modality) => modality.supplierGroupName,
    ) as PaginationModalityViewModel[];
    if (this.modalitiesWithSuppliers?.length) {
      return this.modalitiesWithSuppliers[0];
    }
    return undefined;
  });

  modalitiesWithSuppliers: PaginationModalityViewModel[] = [];

  canViewCarrier = input<boolean>(false);

  shortAddress = shortAddress;

  body = document.body;

  showScroll = false;

  trigger: 'mouseenter' | 'focus' | 'click' | 'focusin' | 'manual' =
    'mouseenter';
}
