import {
  type ErrorHandler,
  Injectable,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import {
  type CreateCspErrorLogRequest,
  type CreateGeneralErrorLogRequest,
  type CreateHttpErrorLogRequest,
  ErrorLogService,
} from '@cca-infra/event-management/v1';
import { ErrorLogConfigToken, NAVIGATOR } from '@cca-environment';
import { BuildInfoService } from '../build-info';

export interface GeneralError {
  message?: string | null;
  stack?: string | null;
}

@Injectable()
export class ApplicationErrorHandler implements ErrorHandler {
  private logService = inject(ErrorLogService);
  private config = inject(ErrorLogConfigToken);
  private buildInfo = inject(BuildInfoService, {
    optional: true,
  });
  private navigator = inject(NAVIGATOR);

  constructor() {
    if (
      this.config.reportCsp &&
      isPlatformBrowser(inject(PLATFORM_ID)) &&
      window &&
      window.SecurityPolicyViolationEvent
    ) {
      window.addEventListener(
        'securitypolicyviolation',
        this.handleSecuritypolicyviolation.bind(this),
      );
    }
  }

  getUserAgent() {
    if (this.navigator) {
      return this.navigator.userAgent;
    }
    return null;
  }

  handleSecuritypolicyviolation(error: SecurityPolicyViolationEvent) {
    this.reportCspError({
      blockedUri: error.blockedURI,
      columnNumber: error.columnNumber,
      documentUri: error.documentURI,
      effectiveDirective: error.effectiveDirective,
      lineNumber: error.lineNumber,
      originalPolicy: error.originalPolicy,
      referrer: error.referrer,
      sourceFile: error.sourceFile,
      statusCode: error.statusCode,
      violatedDirective: error.violatedDirective,
    });
  }

  handleError(error: HttpErrorResponse | GeneralError): void {
    if (error instanceof HttpErrorResponse) {
      if (this.config.reportHttp) {
        if (!this.config.ignoredHttpCodes.includes(error.status)) {
          const headers: Record<string, string> = {};
          for (const header of error.headers.keys()) {
            if (header) {
              const value = error.headers.get(header);
              if (value) {
                headers[header] = value;
              }
            }
          }
          this.reportHttpError({
            url: error.url,
            httpErrorLogHeaders: headers,
            status: error.status,
            error: error.error,
          });
        }
      }
    } else {
      if (this.config.reportError) {
        const generalError = error as GeneralError;
        this.reportError({
          message: generalError?.message ?? (error as string),
          stack: generalError?.stack ?? null,
        });
      }
    }

    console.error(error);
  }

  reportError(errorLog: CreateGeneralErrorLogRequest) {
    if (errorLog && shouldLogError(errorLog)) {
      this.logService
        .reportError({
          ...errorLog,
          ...this.buildInfo?.getBuildInformation(),
        })
        .subscribe(() => {
          if (this.config.logSuccessFullReportsToConsole) {
            console.log('Reported error to LogService');
          }
        });
    }
  }

  reportHttpError(errorLog: CreateHttpErrorLogRequest) {
    if (errorLog) {
      this.logService.reportHttpError(errorLog).subscribe(() => {
        if (this.config.logSuccessFullReportsToConsole) {
          console.log('Reported http error to LogService');
        }
      });
    }
  }

  reportCspError(errorLog: CreateCspErrorLogRequest) {
    if (errorLog) {
      this.logService.reportCspError(errorLog).subscribe(() => {
        if (this.config.logSuccessFullReportsToConsole) {
          console.log('Reported csp error to LogService');
        }
      });
    }
  }
}

// function for filtering out errors
function shouldLogError(errorLog: CreateGeneralErrorLogRequest) {
  if (
    errorLog.message?.includes('The connection was stopped during negotiation.')
  ) {
    return false;
  }
  return true;
}
