import { Injectable, inject, isDevMode } from '@angular/core';
import {
  MatSnackBar,
  type MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';

const defaultSnackbarConfig: MatSnackBarConfig = {
  duration: 5000,
};

export const SnackbarActions = {
  Dismiss: t('snackbar.dismiss'),
  Close: t('snackbar.close'),
  Ok: t('snackbar.ok'),
};

interface SnackbarMessageInput {
  value: string;
  translate?: boolean;
  params?: Record<string, string>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSnackbarMessageInput(x: any): x is SnackbarMessageInput {
  return typeof x != 'string' && 'value' in x;
}

export type SnackbarMessageValueType = SnackbarMessageInput | string;

// interface used internally after converting the string of SnackbarMessageValueType into SnackbarMessageValueConfig
interface SnackbarMessageConfig {
  message: SnackbarMessageInput;
  action: SnackbarMessageInput;
  config?: MatSnackBarConfig;
}

@Injectable({
  providedIn: 'root',
})
export class Snackbar {
  private _snackbar = inject(MatSnackBar);
  private _transloco = inject(TranslocoService);

  openError(
    message: string | SnackbarMessageInput,
    action?: string | SnackbarMessageInput,
  ) {
    this.open(message, action, { panelClass: ['error'] });
  }

  openSuccess(
    message: string | SnackbarMessageInput,
    action?: string | SnackbarMessageInput,
  ) {
    this.open(message, action, { panelClass: ['success'] });
  }

  open(
    message: string | SnackbarMessageInput,
    action?: string | SnackbarMessageInput,
    config?: MatSnackBarConfig,
  ): void {
    let snackbarMessage: SnackbarMessageInput;
    if (isSnackbarMessageInput(message)) {
      snackbarMessage = message;
    } else {
      snackbarMessage = {
        value: message,
        translate: true,
      };
    }

    let snackbarAction: SnackbarMessageInput;
    if (action && isSnackbarMessageInput(action)) {
      snackbarAction = action;
    } else {
      snackbarAction = {
        value: SnackbarActions.Dismiss,
        translate: true,
      };
    }

    // form a snackbar message configuration
    const snackbar: SnackbarMessageConfig = {
      message: snackbarMessage,
      action: snackbarAction,
      config: { ...defaultSnackbarConfig, ...config },
    };

    // if both need translation
    if (snackbar?.message?.translate && snackbar?.action?.translate) {
      this.openTranslated(snackbar as SnackbarMessageConfig);
    }

    // if message needs translation
    else if (snackbar?.message?.translate) {
      this.openTranslatedMessage(snackbar as SnackbarMessageConfig);
    }

    // if action needs translation
    else if (snackbar?.action?.translate) {
      this.openTranslatedAction(snackbar as SnackbarMessageConfig);
    }

    // if both message and action have been translated just open a default snackbar
    else {
      this.openDefault(snackbar as SnackbarMessageConfig);
    }
  }

  public openDefault(message: SnackbarMessageConfig): void {
    this._snackbar.open(
      message.message.value,
      message.action.value,
      message.config,
    );
  }

  public openTranslatedMessage(message: SnackbarMessageConfig) {
    this.loadTranslation(message.message).subscribe((messageTranslation) => {
      this._snackbar.open(messageTranslation, message.action.value, {
        ...defaultSnackbarConfig,
        ...message.config,
      });
    });
  }

  public openTranslatedAction(message: SnackbarMessageConfig) {
    this.loadTranslation(message.action).subscribe((actionTranslation) => {
      this._snackbar.open(message.message.value, actionTranslation, {
        ...defaultSnackbarConfig,
        ...message.config,
      });
    });
  }

  public openTranslated(message: SnackbarMessageConfig) {
    forkJoin([
      this.loadTranslation(message.message),
      this.loadTranslation(message.action),
    ]).subscribe(([translatedMessage, translatedAction]) => {
      this._snackbar.open(translatedMessage, translatedAction, {
        ...defaultSnackbarConfig,
        ...message.config,
      });
    });
  }

  private loadTranslation(
    messageValue: SnackbarMessageInput,
  ): Observable<string> {
    const translationKey = messageValue.value;
    if (typeof translationKey !== 'string') {
      return EMPTY;
    }

    // translation keys always include a dot, due to these always being grouped eg: "foo.bar"
    // with foo being the scope/group/context and bar the key within that group
    // if it does not include a dot, we might be trying to translate a non key
    if (!translationKey.includes('.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isDevMode() &&
        console.warn(
          `Snackbar tried to translate a possible non-key "${translationKey}"`,
        );
      return of(translationKey);
    }

    const translocoScope = translationKey?.split('.')[0];
    return new Observable((observer) => {
      if (!translocoScope) {
        observer.error(`Translation key ${translationKey} is not valid`);
        observer.complete();
      }

      const activeLang = this._transloco.getActiveLang();
      let translation = this._transloco.translate(
        translationKey,
        messageValue.params ?? {},
      );

      if (translation !== translationKey) {
        observer.next(translation);
        observer.complete();
        return;
      }

      this._transloco.load(`${translocoScope}/${activeLang}`).subscribe(() => {
        translation = this._transloco.translate(
          translationKey,
          messageValue.params ?? {},
        );

        observer.next(translation);
        observer.complete();
      });
    });
  }
}
