import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { CountryFlagComponent } from '../country-flag';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, CountryFlagComponent, NgClass],
})
export class ContactComponent {
  readonly noContact = input(false);
  readonly noContactText = input('');
  readonly fullName = input<string | null>();
  readonly phoneNumber = input<string | null>();
  readonly avatarURL = input<string | null | undefined>('');
  readonly responsibilities = input<string | null>();
  readonly email = input<string | null>();
  readonly flagImageUrl = input<string | null>();
  readonly enterpriseName = input<string | null>();
  readonly groupName = input<string | null>();

  initials = computed(() => {
    const fullName = this.fullName();
    if (!fullName) return '';
    if (fullName) {
      return fullName
        .split(' ')
        .map((word) => word[0])
        .filter(
          (letter, _, initials) =>
            initials.indexOf(letter) === 0 ||
            initials.indexOf(letter) === initials.length - 1,
        )
        .join('')
        .toUpperCase();
    }
    return '';
  });
}
