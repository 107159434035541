import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLimiter',
})
export class CdkTextLimiterPipe implements PipeTransform {
  transform(input: string | null | undefined, limit = 0): string {
    if (input && limit && input.length > limit) {
      return input.substring(0, limit - 3) + '...';
    }
    return input ?? '';
  }
}
