import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Injector,
  type OnDestroy,
  ViewContainerRef,
  afterNextRender,
  effect,
  inject,
  input,
  runInInjectionContext,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { type SidePanelContent } from './side-panel.service';

@Component({
  selector: 'cca-drawer',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent implements OnDestroy {
  drawer = inject(MatDrawer);
  content = input<SidePanelContent | null>(null);
  injector = inject(Injector);
  destroyRef = inject(DestroyRef);
  private readonly viewRef = inject(ViewContainerRef);
  private cdr = inject(ChangeDetectorRef);
  timeoutId: NodeJS.Timeout | undefined;

  constructor() {
    effect(() => {
      const content = this.content();
      if (content) {
        clearTimeout(this.timeoutId);
        this.viewRef.clear();

        const options = content.options;
        const componentRef = this.viewRef.createComponent(content.component, {
          injector: options?.injector,
        });

        if (options?.inputs) {
          for (const [key, value] of Object.entries(options.inputs)) {
            componentRef.setInput(key, value);
          }
        }
        componentRef.changeDetectorRef.detectChanges();
        runInInjectionContext(this.injector, () => {
          afterNextRender(() => {
            this.drawer.open();
            componentRef.changeDetectorRef.markForCheck();
          });
        });
      } else {
        this.drawer.close();
        this.timeoutId = setTimeout(() => {
          this.viewRef.clear();
          this.timeoutId = undefined;
        }, 500);
      }

      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
