<div
  class="flex w-full flex-col gap-4"
  [ngClass]="avatarRoundShape() && !editImage() ? 'mb-4' : ''"
>
  @if (!avatarRoundShape()) {
    <div class="flex-shrink-1 flex items-center gap-2">
      <h3 class="text-xl">{{ title() }}</h3>
      @if (description().length) {
        <span class="h-1 w-1 rounded-full surface-neutral-darkest"></span>
        <p class="pt-[.1rem]">
          {{ description() }}
        </p>
      }
    </div>
  }
  <div class="flex gap-4">
    <div class="relative inline-block">
      <div
        class="grid flex-shrink-0 place-items-center overflow-hidden border border-neutral-default"
        [ngClass]="
          avatarRoundShape() ? 'h-20 w-20 rounded-full' : 'h-16 w-48 rounded'
        "
      >
        @if (shownImage) {
          <img
            width="{{ avatarDimensions()[0] }}"
            height="{{ avatarDimensions()[1] }}"
            [src]="shownImage"
            [ngClass]="avatarRoundShape() ? 'rounded-full' : 'rounded'"
            [ngClass]="editImage() ? 'cursor-pointer' : ''"
            (click)="uploadAvatar()"
          />
        } @else {
          <cca-icon
            [icon]="icon()"
            class="text-4xl text-neutral-disabled"
            [ngClass]="editImage() ? 'cursor-pointer' : ''"
            (click)="uploadAvatar()"
          ></cca-icon>
        }
      </div>
      @if (avatarRoundShape()) {
        <button
          class="absolute bottom-0 right-0 h-8 w-8 translate-x-1/4 translate-y-1/4 transform rounded-full border p-1 shadow surface-neutral-light border-neutral-default"
          (click)="uploadAvatar()"
        >
          <cca-icon icon="pencil"></cca-icon>
        </button>
      }
    </div>
    @if (avatarRoundShape()) {
      <div class="flex-shrink-1 flex flex-col justify-center">
        <h3 class="pb-1 text-xl">{{ title() }}</h3>
        @if (description().length > 0) {
          <p>
            {{ description() }}
          </p>
        }
      </div>
    }

    @let statusFlavor = this.statusFlavor();
    @if (status() && statusFlavor) {
      <cca-status-badge class="mt-3" [flavor]="statusFlavor">
        {{ status() }}
      </cca-status-badge>
    }
    <ng-content></ng-content>
  </div>
</div>
