import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type GroupHeaderDataViewModel } from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GroupHeaderService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getGroupHeaderData(groupId: id) {
    return this.http.get<GroupHeaderDataViewModel>(
      `${this.url}detail/header-data/${groupId}`,
    );
  }
}
