<ng-container *transloco="let t; read: 'booking.generalDialog'">
  <div class="dialog-container">
    <header>
      <h2>{{ label() ?? t('label') }}</h2>
      @if (hasTwoButtons()) {
        <button [mat-dialog-close]="null" class="dialog-close-button">
          <cca-icon icon="xmark"></cca-icon>
        </button>
      }
    </header>
    <main>
      <span>{{ description() ?? t('description') }}</span>
    </main>
    <footer>
      @if (hasTwoButtons()) {
        <button
          mat-stroked-button
          [mat-dialog-close]="true"
          color="primary"
          (click)="confirmButtonClick.emit()"
        >
          {{ confirmButton() ?? t('confirmButton') }}
        </button>
      }
      <button
        mat-flat-button
        mat-dialog-close
        color="primary"
        (click)="cancelButtonClick.emit()"
      >
        {{ cancelButton() ?? t('cancelButton') }}
      </button>
    </footer>
  </div>
</ng-container>
