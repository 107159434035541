import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { type SequenceLocationDTO } from '@cca-infra/sequence-management/v1';
import { CdkTransformPipe } from '@cca-common/cdk';
import { LocationType } from '@cca-infra/location-management/v1';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-suggested-location',
  imports: [
    CommonModule,
    MatButtonModule,
    CdkTransformPipe,
    TranslocoModule,
    IconComponent,
  ],
  templateUrl: './suggested-location.component.html',
  styleUrl: './suggested-location.component.scss',
  providers: [provideTranslocoScope('suggestedLocation')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedLocationComponent {
  activeLocation = input<boolean>(false);
  description = input<string>();
  locationType = input<LocationType>();
  suggestedLocation = input.required<SequenceLocationDTO>();
  selectSuggestedLocation = output<SequenceLocationDTO>();

  getLocationTypeTranslation(
    locationType: LocationType | undefined | null,
  ): string {
    switch (locationType) {
      case LocationType.Business:
        return t('suggestedLocation.locationType.business');
      case LocationType.Warehouse:
        return t('suggestedLocation.locationType.warehouse');
      case LocationType.Undefined:
      case LocationType.Other:
      default:
        return t('suggestedLocation.locationType.other');
    }
  }

  getLocationIcon(locationType: LocationType | undefined | null): string {
    switch (locationType) {
      case LocationType.Business:
        return '/assets/images/booking/location/location-business.svg';
      case LocationType.Warehouse:
        return '/assets/images/booking/location/location-warehouse.svg';
      case LocationType.Undefined:
      case LocationType.Other:
      default:
        return '/assets/images/booking/location/location-other.svg';
    }
  }
}
