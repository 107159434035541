import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs/internal/Observable';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type AcceptManagedTransBidByExternalRequest,
  type AddCarrierToTenderRequest,
  type AddCarriersToManagedTransportTenderRequest,
  type ExternalManagedTransUserGroupsViewModel,
  type GetAllCarrierGroupsForManagedTransportTenderExternalRequest,
  type ManagedTransCtrlChainOfferFromCarrierBidsRequest,
  type ManagedTransInternalNotInterestedRequest,
  type ManagedTransManualCtrlChainOfferRequest,
  type ManagedTransTenderIterationsItemViewModel,
  type ManagedTransportTenderIterationBasicDetailExternalViewModel,
  type ManagedTransportTenderIterationBasicDetailInternalViewModel,
  type RefuseManagedTransBidByInternalRequest,
  type RejectManagedTransBidByExternalRequest,
  type RequestTenderBidsViewModel,
  type RespondToTenderRequest,
  type SendPricePredictionFeedbackRequest,
  type ShipmentItem,
  type TenderCarriersViewModel,
  type TenderDetailViewModel,
  type TenderIterationsItemViewModel,
  type TenderRequestSimpleViewModel,
  type TenderSideSummaryViewModel,
} from '../model';

export interface DefaultTenderRequestModel {
  requestId: string | null;
  orderId: string | null;
}

@Injectable({ providedIn: 'root' })
export class TenderService extends BaseApiService {
  constructor() {
    super(`tender.v1.request`);
  }

  tenderPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  shipmentPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<ShipmentItem>>(
      `${this.url}paginated-shipments`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  tenderDetailPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<TenderIterationsItemViewModel>>(
      `${this.url}paginated-tender-iterations`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  tenderDetailPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-tenders-filters`);
  }

  tenderMTDetailPaginationFilters() {
    return this.http.get<FilterInfo>(
      `${this.url}paginated-tender-iteration-filters`,
    );
  }

  tenderDetailPaginationMT(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<ManagedTransTenderIterationsItemViewModel>
    >(`${this.url}paginated-managed-trans-tender-iterations`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  createTenderRequest(
    requestId: id | null,
    orderId: id | null,
    expiryDate: number,
    comment: string,
    carrierGroupIds: id[],
    requestIterationIds: id[],
    operatorUserId: id | null,
  ) {
    return this.http.post<TenderRequestSimpleViewModel>(
      `${this.url}create-tender-request`,
      {
        requestId,
        orderId,
        expiryDate,
        comment,
        carrierGroupIds,
        requestIterationIds,
        operatorUserId,
      },
    );
  }

  createUTurnTenderRequest(
    requestId: id | null,
    orderId: id | null,
    expiryDate: number,
    comment: string,
    operatorUserId: id | null,
    requestIterationIds: id[],
    targetRate: number | null,
  ) {
    return this.http.post<TenderRequestSimpleViewModel>(
      `${this.url}create-uturn-tender-request`,
      {
        requestId,
        orderId,
        expiryDate,
        comment,
        operatorUserId,
        requestIterationIds,
        targetRate,
      },
    );
  }

  tenderDetail(
    tenderReferenceNumber: string,
    iterationId?: id | undefined | null,
  ) {
    return this.http.post<TenderDetailViewModel>(
      `${this.url}get-tender-details`,
      {
        iterationId,
        tenderReferenceNumber,
      },
    );
  }

  tenderDetailUnauthorized(
    tenderReferenceNumber: string,
    hash: string | undefined,
  ) {
    return this.http.post<TenderDetailViewModel>(
      `${this.url}get-tender-details-unauthorized`,
      { hash, tenderReferenceNumber },
    );
  }

  tenderSideSummary(tenderReferenceNumber: string) {
    return this.http.post<TenderSideSummaryViewModel>(
      `${this.url}get-tender-side-summary`,
      {
        tenderReferenceNumber,
      },
    );
  }

  respondTender(
    hash: string | undefined,
    tenderRequestRowId: string,
    carrierGroupId: string | undefined,
    carrierResponseStatus: number,
    offerPrice: number | undefined,
    comment: string | undefined,
    expiryDate: number | undefined,
  ) {
    return this.http.post<RespondToTenderRequest>(`${this.url}respond-tender`, {
      hash,
      tenderRequestRowId,
      carrierGroupId,
      carrierResponseStatus,
      offerPrice,
      comment,
      expiryDate,
    });
  }

  updateComments(
    comment: string,
    tenderReferenceNumber: string,
  ): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}update-tender-comment`, {
      tenderReferenceNumber,
      comment,
    });
  }

  addCarrierToShipments(body: AddCarrierToTenderRequest): Observable<string> {
    return this.http.post<string>(`${this.url}add-carrier-to-shipments`, body);
  }

  getShipmentCarriers(tenderRequestId: id) {
    return this.http.get<TenderCarriersViewModel>(
      `${this.url}get-carriers/${tenderRequestId}`,
    );
  }

  getTenderBidsByOrderId(orderId: id) {
    return this.http.get<RequestTenderBidsViewModel>(
      `${this.url}get-request-tender-bids-order/${orderId}`,
    );
  }

  getTenderBidsByRequestId(requestId: id) {
    return this.http.get<RequestTenderBidsViewModel>(
      `${this.url}get-request-tender-bids-request/${requestId}`,
    );
  }

  getDefaultComment(request: DefaultTenderRequestModel) {
    return this.http.post<string>(
      `${this.url}get-default-tender-comment`,
      request,
    );
  }

  addCarriersToManagedTransportTenderExternal(
    body: AddCarriersToManagedTransportTenderRequest,
  ) {
    return this.http.post<string>(
      `${this.url}add-carriers-to-managed-transport-tender-external`,
      body,
    );
  }

  addCarriersToManagedTransportTenderInternal(
    body: AddCarriersToManagedTransportTenderRequest,
  ) {
    return this.http.post<string>(
      `${this.url}add-carriers-to-managed-transport-tender-internal`,
      body,
    );
  }

  acceptManagedTransBidByExternal(
    tenderRequestRowId: id,
    tenderRequestCarrierId: id,
  ) {
    return this.http.post<AcceptManagedTransBidByExternalRequest>(
      `${this.url}accept-managed-trans-bid-external`,
      {
        tenderRequestRowId,
        tenderRequestCarrierId,
      },
    );
  }

  rejectManagedTransBidByExternal(
    tenderRequestRowId: id,
    tenderRequestCarrierId: id,
  ) {
    return this.http.post<RejectManagedTransBidByExternalRequest>(
      `${this.url}reject-managed-trans-bid-external`,
      {
        tenderRequestRowId,
        tenderRequestCarrierId,
      },
    );
  }

  tenderDetailMTInternal(requestIterationId: string) {
    return this.http.post<ManagedTransportTenderIterationBasicDetailInternalViewModel>(
      `${this.url}get-internal-managed-transport-tender-iteration-basic-details`,
      {
        requestIterationId,
      },
    );
  }

  tenderDetailMT(requestIterationId: string) {
    return this.http.post<ManagedTransportTenderIterationBasicDetailExternalViewModel>(
      `${this.url}get-external-managed-transport-tender-iteration-basic-details`,
      {
        requestIterationId,
      },
    );
  }

  getTenderDetailMTcarrierGroups(
    body: GetAllCarrierGroupsForManagedTransportTenderExternalRequest,
  ) {
    return this.http.post<ExternalManagedTransUserGroupsViewModel[]>(
      `${this.url}get-all-carrier-groups-for-managed-trans-tender-external`,
      body,
    );
  }

  offerFromCarrierMTInternal(
    offer: ManagedTransCtrlChainOfferFromCarrierBidsRequest,
  ) {
    return this.http.post<boolean>(
      `${this.url}internal-managed-trans-offer-from-carrier`,
      offer,
    );
  }

  offerManualMTInternal(manual: ManagedTransManualCtrlChainOfferRequest) {
    return this.http.post<boolean>(
      `${this.url}internal-managed-trans-offer-manual`,
      manual,
    );
  }

  setIterationAsNotInterested(tenderRequestRowId: id) {
    return this.http.post<ManagedTransInternalNotInterestedRequest>(
      `${this.url}internal-managed-trans-not-interested`,
      {
        tenderRequestRowId,
      },
    );
  }

  refuseManagedTransBidByInternal(
    tenderRequestRowId: id,
    tenderRequestCarrierId: id,
  ) {
    return this.http.post<RefuseManagedTransBidByInternalRequest>(
      `${this.url}internal-refuse-bid`,
      {
        tenderRequestRowId,
        tenderRequestCarrierId,
      },
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  sendPricePredictionFeedback(
    pricePredictionFeedback: SendPricePredictionFeedbackRequest,
  ) {
    return this.http.post<boolean>(
      `${this.url}price-prediction-feedback`,
      pricePredictionFeedback,
    );
  }
}
