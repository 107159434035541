import { Injectable } from '@angular/core';
import { type PreloadingStrategy, type Route } from '@angular/router';
import { type Observable, timer, of, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OptInLoadingStrategy implements PreloadingStrategy {
  preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
    if (route.data && route.data.preload) {
      const delay = route.data?.preloadDelayInSeconds
        ? route.data?.preloadDelayInSeconds
        : 0;
      if (delay > 0) {
        return timer(delay * 1000).pipe(map(() => fn()));
      } else {
        return fn();
      }
    }
    return of(null);
  }
}
