import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  type OnDestroy,
  inject,
} from '@angular/core';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { type Subscription } from 'rxjs';

@Directive({
  selector: 'input[ccaInputLocale]',
  exportAs: 'ccaInputLocale',
})
export class CdkInputLocaleDirective implements OnDestroy {
  private localeService = inject(TranslocoLocaleService);
  private element = inject(ElementRef);
  private cdr = inject(ChangeDetectorRef);

  // subscription
  localeSubscription: Subscription | null = null;

  constructor() {
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      (locale) => {
        (this.element.nativeElement as HTMLInputElement).lang = locale;
        this.cdr.markForCheck();
      },
    );
  }

  ngOnDestroy(): void {
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();

      // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
      // callback within its `destination` property, preventing classes from being GC'd.
      this.localeSubscription = null;
    }
  }
}
