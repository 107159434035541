import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cca-page-state',
  imports: [MatButtonModule, NgClass],
  templateUrl: './page-state.component.html',
  styleUrls: ['./page-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageStateComponent {
  readonly image = input('assets/images/order-empty-state.svg');
  readonly text = input('');
  readonly subText = input('');
  readonly buttonText = input('');
  readonly smallFormat = input(false);
  readonly buttonClicked = output<void>();
}
