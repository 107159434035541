import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@ngrx/component';

type ConfirmationPageTranslationKeys =
  | 'title'
  | 'referenceLabel'
  | 'paragraph'
  | 'primaryButtonLabel'
  | 'secondaryButtonLabel'
  | 'subtitle';

type ConfirmationPageTranslations = Record<
  ConfirmationPageTranslationKeys,
  string
>;

@Component({
  selector: 'cca-confirmation-page',
  imports: [LetDirective, MatButtonModule],
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationPageComponent {
  readonly image = input('assets/images/booking/circle-checkmark.svg');
  readonly referenceNumber = input<string>();
  readonly translations = input<ConfirmationPageTranslations | undefined>({
    title: 'Your request is received',
    referenceLabel: 'Request number:',
    paragraph: '',
    primaryButtonLabel: 'To green',
    secondaryButtonLabel: 'To white',
    subtitle: '',
  });

  readonly primaryButtonClick = output<void>();
  readonly secondaryButtonClick = output<void>();
}
