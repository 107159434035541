<ng-container *transloco="let t; read: 'richTextViewer'">
  @if (safeText()) {
    @if (large()) {
      <div
        class="surface-neutral-light/100 word-break relative overflow-auto"
        [ngClass]="styling()"
      >
        <cca-html-viewer [html]="safeText()"></cca-html-viewer>
      </div>
    } @else {
      @defer {
        <div class="flex flex-col">
          <div
            class="text-container surface-neutral-light/100 word-break relative"
            [class.expanded]="isExpanded"
            [ngClass]="styling()"
          >
            <cca-html-viewer
              [html]="safeText()"
              (containerDimensionsChange)="
                onHtmlContainerDimensionsChange($event)
              "
            ></cca-html-viewer>
          </div>

          @if (isOverflow()) {
            <cca-show-more-less
              [showMoreLabel]="t('showMore')"
              [showLessLabel]="t('showLess')"
              [isShowMore]="!isExpanded"
              (isShowMoreChanged)="isExpanded = !isExpanded"
            >
            </cca-show-more-less>
          }
        </div>
      }
    }
  }
</ng-container>
