<div class="flex">
  <div
    [ngClass]="{
      scrollable: list.isOverflow,
    }"
    class="list flex grow overflow-x-scroll"
    nextPreviousScrollbar
    #list="ccaNextPreviousScrollbar"
    ccaNextPreviousScrollbar
    [scrollUnit]="scrollUnit()"
  >
    <!-- Inject list of items which display-->
    <ng-content></ng-content>
  </div>

  @if (list.isOverflow) {
    <button
      class="scroll-button -order-1 rounded-l-xl"
      [class.disable]="!list.canScrollStart"
      (click)="list.scroll(-1)"
    >
      <cca-icon icon="chevron-left"></cca-icon>
    </button>

    <button
      class="scroll-button rounded-r-xl"
      [class.disable]="!list.canScrollEnd"
      (click)="list.scroll(1)"
    >
      <cca-icon icon="chevron-right"></cca-icon>
    </button>
  }
</div>
