import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cca-trip-indicator',
  imports: [CommonModule],
  templateUrl: './trip-indicator.component.html',
  styleUrl: './trip-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripIndicatorComponent {
  readonly text = input('');
  readonly numberOfStops = input(0);
  readonly stopsText = input('');
}
