import {
  ChangeDetectionStrategy,
  Component,
  type OnChanges,
  input,
} from '@angular/core';
import { AvatarComponent, Mode } from '../avatar';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

export interface AvatarGroupItem {
  id?: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatarURL?: string | null;
}
@Component({
  selector: 'cca-avatar-group',
  imports: [AvatarComponent, NgxTippyModule],
  templateUrl: './avatar-group.component.html',
  styleUrl: './avatar-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarGroupComponent implements OnChanges {
  readonly visibleLength = input(5);
  readonly items = input<AvatarGroupItem[] | undefined>([]);
  /**@param size tailwind class number eg size=8 -> h-8 w-8  */
  readonly size = input(8);

  Mode = Mode;
  omittedItems: AvatarGroupItem[] = [];
  body = document.body;

  ngOnChanges() {
    this.omittedItems = this.items()?.slice(this.visibleLength()) ?? [];
  }

  getInitials(item: AvatarGroupItem | undefined): string {
    if (!item) return '';
    if (item.firstName && item.lastName) {
      return `${item.firstName[0]}${item.lastName[0]}`.toUpperCase();
    }

    if (item.name) {
      return item.name
        .split(' ')
        .map((word) => word[0])
        .filter(
          (letter, _, initials) =>
            initials.indexOf(letter) === 0 ||
            initials.indexOf(letter) === initials.length - 1,
        )
        .join('')
        .toUpperCase();
    }
    return '';
  }

  getFullName = (item: AvatarGroupItem | undefined) =>
    item ? (item.name ?? `${item.firstName ?? ''} ${item.lastName ?? ''}`) : '';
}
