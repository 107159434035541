import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type AcceptTermsAndConditionsRequest,
  type CheckLegalDocumentAcceptanceRequest,
  type FilterChoiceOption,
  type LegalDocumentPublishedVersionViewModel,
  type ReadPublishedLegalDocumentRequest,
  type SignHistoryItemViewModel,
} from '../model';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
} from '@cca-infra/common';
import { type UserAcceptedViewModel } from '../model/userAcceptedViewModel';

@Injectable({
  providedIn: 'root',
})
export class AcceptanceService extends BaseApiService {
  constructor() {
    super('legal.v1.acceptance');
  }

  readLegalDocument(request: ReadPublishedLegalDocumentRequest) {
    return this.http.post<LegalDocumentPublishedVersionViewModel>(
      `${this.url}read-legal-document`,
      request,
    );
  }

  paginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<SignHistoryItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  paginatedFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  paginatedExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  checkLegalDocumentAcceptance(request: CheckLegalDocumentAcceptanceRequest) {
    return this.http.post<UserAcceptedViewModel>(
      `${this.url}check-legal-document-acceptance`,
      request,
    );
  }

  acceptTermsAndConditions(request: AcceptTermsAndConditionsRequest) {
    return this.http.post<boolean>(
      `${this.url}accept-terms-and-conditions`,
      request,
    );
  }
}
