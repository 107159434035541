import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkTextLimiterPipe } from '@cca-common/cdk';

@Component({
  imports: [MatTooltipModule, CdkTextLimiterPipe],
  selector: 'cca-text',
  templateUrl: './text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextComponent {
  readonly text = input('');
  readonly maxLength = input(24);
}
