import { inject } from '@angular/core';
import {
  patchState,
  signalStore,
  signalStoreFeature,
  type StateSource,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

export type RegistryState = { stores: Record<string, StateSource<object>> };

export const DevStoreRegistryStore = signalStore(
  { providedIn: 'root' },
  withState<RegistryState>({ stores: {} }),
  withMethods((store) => ({
    track(id: string, storeObj: StateSource<object>): void {
      patchState(store, () => ({
        stores: {
          ...store.stores(),
          ...{
            [id]: storeObj,
          },
        },
      }));
    },
    untrack(id: string): void {
      const stores = { ...store.stores() };
      if (stores[id]) {
        delete stores[id];
      }

      patchState(store, () => ({
        stores: stores,
      }));
    },
  })),
);

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withDevTracking<_>(uniqueIdentifier: string) {
  return signalStoreFeature(
    withHooks((store) => {
      const storeRegistry = inject(DevStoreRegistryStore);
      return {
        onInit() {
          storeRegistry.track(uniqueIdentifier, store);
        },
        onDestroy() {
          storeRegistry.untrack(uniqueIdentifier);
        },
      };
    }),
  );
}
