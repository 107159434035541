import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { type StatusBadgeFlavor } from '@cca-common/core';

export interface Status {
  text: string;
  number: number;
  flavor: StatusBadgeFlavor;
}

@Component({
  selector: 'cca-statuses-count',
  imports: [NgClass],
  templateUrl: './statuses-count.component.html',
  styleUrl: './statuses-count.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusesCountComponent {
  readonly statuses = input<Status[]>();
}
