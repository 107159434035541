import { type Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./landing-page').then((m) => m.LandingPageComponent),
  },
  {
    path: 'api/:apiName',
    loadComponent: () =>
      import('./swagger-page').then((m) => m.SwaggerPageComponent),
    data: { title: 'ChainCargo API' },
  },
  {
    path: 'docs/:apiName',
    loadComponent: () =>
      import('./api-documentation-component').then(
        (m) => m.ApiDocumentationComponentComponent,
      ),
    data: { title: 'Documentation' },
  },
  { path: '**', redirectTo: 'getting-started' },
];
