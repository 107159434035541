import {
  afterRenderEffect,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'cca-html-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
  template: `<div class="html-container" [innerHTML]="html()"></div>`,
  styles: `
    p,
    ol,
    li,
    ul {
      margin: 0;
    }
    .mention {
      color: #2e85b4;
      margin-right: 0.25rem;
      height: 24px;
      width: 65px;
      border-radius: 6px;
      padding: 3px;
      margin-right: 3px;
      margin-left: 3px;
      font-size: 0.75rem;
      user-select: all;
      --tw-bg-opacity: 1;
      background-color: rgb(239 236 248 / var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgb(83 56 158 / var(--tw-text-opacity));
    }
    .mention:before {
      content: attr(data-task-owner) '@';
    }
  `,
})
export class HtmlViewerComponent {
  private element = inject(ElementRef);

  html = input.required<TrustedHTML | SafeHtml | string | null | undefined>();

  containerDimensionsChange = output<{
    scrollHeight: number;
    clientHeight: number;
  }>();

  constructor() {
    afterRenderEffect({
      read: () => {
        const html = this.html();

        if (html) {
          this.updateContainerDimensions();
        }
      },
    });
  }

  private updateContainerDimensions() {
    const container =
      this.element.nativeElement.shadowRoot.querySelector('.html-container');
    this.containerDimensionsChange.emit({
      scrollHeight: container.scrollHeight,
      clientHeight: container.clientHeight,
    });
  }
}
