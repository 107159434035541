<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="example-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
  This padding value depends on the mat-icon-button width. -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    #normalTreeNode="matTreeNode"
    matTreeNodeToggle
    #treeNode="matTreeNode"
    [ngClass]="{
      last: node.lastNode,
      'mat-tree-node flex flex-row border-l-2 border-neutral-default':
        treeNode.level !== 0,
    }"
  >
    <div
      [ngClass]="{ hidden: treeNode.level === 0 }"
      class="borderBottomWithRadius h-2 w-1 border-b-2 border-l-2 border-neutral-default"
    ></div>

    <div
      [ngClass]="{ hidden: treeNode.level === 0 }"
      class="h-2 w-3 border-b-2 border-neutral-default"
    ></div>
    <div class="nodeLine ml-[3.1rem]">
      @if (showSelectableRadioBtn()) {
        <mat-radio-button
          [color]="'primary'"
          (change)="nodeSelected.emit($event)"
          [disabled]="!node.isSelectable"
          [checked]="node.id === selectedNode()"
          [value]="node.id"
        >
          <span
            [class.disabled]="!node.isSelectable"
            class="cursor-pointer text-base font-medium"
          >
            {{ node.name }}
          </span>
        </mat-radio-button>
      } @else {
        <ng-container
          *ngTemplateOutlet="
            editMenuTemplate() ?? null;
            context: { $implicit: node }
          "
        ></ng-container>
        <span class="text-base font-medium">
          {{ node.name }}
        </span>
      }

      <ng-container
        *ngTemplateOutlet="tailTemplate() ?? null; context: { $implicit: node }"
      ></ng-container>
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    #treeNode="matNestedTreeNode"
  >
    <div
      [ngClass]="{
        last: node.lastNode,
        'mat-tree-node flex! flex-row! items-center! border-l-2 border-neutral-default':
          treeNode.level !== 0,
      }"
    >
      <div
        [ngClass]="{ hidden: treeNode.level === 0 }"
        class="borderBottomWithRadius h-2 w-1 border-b-2 border-l-2 border-neutral-default"
      ></div>
      <div
        [ngClass]="{ hidden: treeNode.level === 0 }"
        class="horizontal-line"
      ></div>
      <div class="nodeLine">
        <button
          class="border-white! z-50 border-l-2"
          type="button"
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <cca-icon
            class="mat-icon-rtl-mirror text-brand-default"
            [icon]="
              treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'
            "
          >
          </cca-icon>
        </button>
        @if (showSelectableRadioBtn()) {
          <mat-radio-button
            [color]="'primary'"
            class="text-brand-default"
            [disabled]="!node.isSelectable"
            (change)="nodeSelected.emit($event)"
            [value]="node.id"
            [checked]="node.id === selectedNode()"
          >
            <span
              [class.disabled]="!node.isSelectable"
              class="cursor-pointer text-base font-medium"
            >
              {{ node.name }}
            </span>
          </mat-radio-button>
        } @else {
          <ng-container
            *ngTemplateOutlet="
              editMenuTemplate() ?? null;
              context: { $implicit: node }
            "
          ></ng-container>

          <span class="text-base font-medium">
            {{ node.name }}
          </span>
        }

        <ng-container
          *ngTemplateOutlet="
            tailTemplate() ?? null;
            context: { $implicit: node }
          "
        ></ng-container>
      </div>
    </div>

    <!-- There is inline padding applied to this div using styles.
    This padding value depends on the mat-icon-button width.  -->
    <div
      class="pl-10"
      [ngClass]="{ notlast: treeNode.level !== 0 }"
      [class.example-tree-invisible]="!treeControl.isExpanded(node)"
      [class.pl-6]="treeNode.level === 0"
      role="group"
    >
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
