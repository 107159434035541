import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type CreateGlossaryWordRequest,
  type FilterSetViewModel,
  type PaginatedGlossaryViewModel,
  type UpdateGlossaryValueRequest,
  type UpdateGlossaryWordDescriptionRequest,
} from '../model';
import {
  AdaptPaginationRequest,
  type id,
  type PaginationRequest,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GlossaryService extends BaseApiService {
  constructor() {
    super(`translation.v1.glossary`);
  }

  paginated(paginatedGlossaryRequest: PaginationRequest) {
    return this.http.post<PaginatedGlossaryViewModel>(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginatedGlossaryRequest),
    });
  }

  paginatedFilters() {
    return this.http.get<FilterSetViewModel[]>(`${this.url}paginated-filters`);
  }

  createWord(createGlossaryWordRequest: CreateGlossaryWordRequest) {
    return this.http.put<boolean>(
      `${this.url}create-word`,
      createGlossaryWordRequest,
    );
  }

  deleteWord(wordId: id) {
    return this.http.delete<boolean>(`${this.url}delete-word/${wordId}`);
  }

  updateValue(updateGlossaryValueRequest: UpdateGlossaryValueRequest) {
    return this.http.put<boolean>(
      `${this.url}update-value`,
      updateGlossaryValueRequest,
    );
  }

  updateDescription(
    updateGlossaryWordDescriptionRequest: UpdateGlossaryWordDescriptionRequest,
  ) {
    return this.http.put<boolean>(
      `${this.url}update-description`,
      updateGlossaryWordDescriptionRequest,
    );
  }
}
