export enum PermissionKey {
  ActionsCarrier = 'actions-carrier',
  ActionsGeneral = 'actions-general',
  ActionsShipper = 'actions-shipper',
  AnalyticBuypriceprediction = 'analytic-buypriceprediction',
  AnalyticGeneral = 'analytic-general',
  BookingApi = 'booking-api',
  BookingGeneral = 'booking-general',
  BookingSelectbooker = 'booking-selectbooker',
  BookingSelectgroup = 'booking-selectgroup',
  CarrierorderGeneral = 'carrierorder-general',
  DashboardCarrier = 'dashboard-carrier',
  DashboardCustomersuccess = 'dashboard-customersuccess',
  DashboardEndofweek = 'dashboard-endofweek',
  DashboardFinance = 'dashboard-finance',
  DashboardGeneral = 'dashboard-general',
  DashboardOperator = 'dashboard-operator',
  DashboardSales = 'dashboard-sales',
  DashboardShipper = 'dashboard-shipper',
  EventlogGeneral = 'eventlog-general',
  FinanceCtmsownedgrouprates = 'finance-ctmsownedgrouprates',
  FinanceGeneral = 'finance-general',
  FinanceRates = 'finance-rates',
  FinanceSets = 'finance-sets',
  FleetGeneral = 'fleet-general',
  FleetTracking = 'fleet-tracking',
  InternalAdmin = 'internal-admin',
  InternalAnalyst = 'internal-analyst',
  InternalCarriermanagement = 'internal-carriermanagement',
  InternalCtrlchainmanager = 'internal-ctrlchainmanager',
  InternalCustomersuccess = 'internal-customersuccess',
  InternalEnterprisemanager = 'internal-enterprisemanager',
  InternalFinance = 'internal-finance',
  InternalGeneral = 'internal-general',
  InternalGroupmanager = 'internal-groupmanager',
  InternalLegalteam = 'internal-legalteam',
  InternalManager = 'internal-manager',
  InternalMarketing = 'internal-marketing',
  InternalSales = 'internal-sales',
  InternalTenderops = 'internal-tenderops',
  LaneCarrier = 'lane-carrier',
  LaneCarrierrate = 'lane-carrierrate',
  LaneDocument = 'lane-document',
  LaneGeneral = 'lane-general',
  LaneInstructions = 'lane-instructions',
  LaneShipperdetails = 'lane-shipperdetails',
  LaneVehicledetails = 'lane-vehicledetails',
  LegalGeneral = 'legal-general',
  LegalTermsandconditions = 'legal-termsandconditions',
  LegalTermsofuse = 'legal-termsofuse',
  LocationAddress = 'location-address',
  LocationAddresscompany = 'location-addresscompany',
  LocationCarriercorridors = 'location-carriercorridors',
  LocationCarrierlanes = 'location-carrierlanes',
  LocationCtmsownedgroupaddress = 'location-ctmsownedgroupaddress',
  LocationGeneral = 'location-general',
  LocationInboundoutbound = 'location-inboundoutbound',
  LocationLanes = 'location-lanes',
  LocationLanescarrier = 'location-lanescarrier',
  LocationLanesdocument = 'location-lanesdocument',
  LocationRouteplan = 'location-routeplan',
  MobileCarrier = 'mobile-carrier',
  MobileDriver = 'mobile-driver',
  MobileGeneral = 'mobile-general',
  MobileInternal = 'mobile-internal',
  OrderActions = 'order-actions',
  OrderCancel = 'order-cancel',
  OrderCargo = 'order-cargo',
  OrderCommunication = 'order-communication',
  OrderCompanylevel = 'order-companylevel',
  OrderDesireddatetimes = 'order-desireddatetimes',
  OrderDocuments = 'order-documents',
  OrderGeneral = 'order-general',
  OrderHistoricalroute = 'order-historicalroute',
  OrderLocations = 'order-locations',
  OrderPricing = 'order-pricing',
  OrderPricingcarrier = 'order-pricingcarrier',
  OrderPricingshipper = 'order-pricingshipper',
  OrderReset = 'order-reset',
  OrderTracking = 'order-tracking',
  OrderTransport = 'order-transport',
  RequestCargo = 'request-cargo',
  RequestCommunication = 'request-communication',
  RequestDesireddatetimes = 'request-desireddatetimes',
  RequestGeneral = 'request-general',
  RequestLanerequest = 'request-lanerequest',
  RequestLanerequestrow = 'request-lanerequestrow',
  RequestLanerequeststatus = 'request-lanerequeststatus',
  RequestQuotation = 'request-quotation',
  RequestTransport = 'request-transport',
  TaskGeneral = 'task-general',
  TenderCommunication = 'tender-communication',
  TenderGeneral = 'tender-general',
  TenderLanerequests = 'tender-lanerequests',
  TenderRequests = 'tender-requests',
  TranslationGeneral = 'translation-general',
  TranslationGlossary = 'translation-glossary',
  TripGeneral = 'trip-general',
  TripPlanning = 'trip-planning',
  UserCarrier = 'user-carrier',
  UserEnterprise = 'user-enterprise',
  UserGeneral = 'user-general',
  UserProfile = 'user-profile',
  UserShipper = 'user-shipper',
  UserUpdatepassword = 'user-updatepassword',
  UsergroupCarrieractivation = 'usergroup-carrieractivation',
  UsergroupCarriergroup = 'usergroup-carriergroup',
  UsergroupCarrierprospectgroup = 'usergroup-carrierprospectgroup',
  UsergroupCo2 = 'usergroup-co2',
  UsergroupCtms = 'usergroup-ctms',
  UsergroupFeature = 'usergroup-feature',
  UsergroupFinance = 'usergroup-finance',
  UsergroupGeneral = 'usergroup-general',
  UsergroupManager = 'usergroup-manager',
  UsergroupNotification = 'usergroup-notification',
  UsergroupShipperactivation = 'usergroup-shipperactivation',
  UsergroupShippergroup = 'usergroup-shippergroup',
  UsergroupShipperinstruction = 'usergroup-shipperinstruction',
  UsergroupShipperprospectgroup = 'usergroup-shipperprospectgroup',
  WarehouseGeneral = 'warehouse-general',
  WarehouseHandleunitdetail = 'warehouse-handleunitdetail',
  WarehouseLocationdetail = 'warehouse-locationdetail',
  WarehouseOrder = 'warehouse-order',
  WarehousePick = 'warehouse-pick',
  WarehousePutaway = 'warehouse-putaway',
  WarehouseReceipts = 'warehouse-receipts',
  WarehouseShip = 'warehouse-ship',
  WarehouseTransport = 'warehouse-transport',
  WarehouseWarehouseoverview = 'warehouse-warehouseoverview',
}
