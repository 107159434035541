import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnChanges,
  type TemplateRef,
  contentChild,
  output,
  input,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { type MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { IconComponent } from '../icon';
import { type DomainType, type id } from '@cca-infra/common';
import { NgClass, NgTemplateOutlet } from '@angular/common';

export interface TreeViewNode {
  id: id;
  name?: string | null;
  isSelectable?: boolean;
  parentId?: id;
  children?: TreeViewNode[] | null;
  lastNode?: boolean;
  anyUsersInGroup?: boolean;
  disabled?: boolean;
  expandable?: boolean;
  level?: number;
  currentUserIsInGroup?: boolean;
  isRoot?: boolean;
  isEnterprise?: boolean;
  pointsToFinancialGroup?: boolean;
  billToName?: string | null;
  billToGroupId?: boolean;
  domainGroupTypeIds?: DomainType[];
}

interface TemplateContext {
  $implicit: TreeViewNode;
}

@Component({
  selector: 'cca-tree-view',
  imports: [
    NgClass,
    NgTemplateOutlet,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    IconComponent,
  ],
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewComponent implements OnChanges {
  readonly editMenuTemplate =
    contentChild<TemplateRef<TemplateContext>>('editMenuTemplate');
  readonly tailTemplate =
    contentChild<TemplateRef<TemplateContext>>('tailTemplate');

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set groupData(value: TreeViewNode[]) {
    this.dataSource.data = value;
    this.treeControl.dataNodes = value;
  }
  readonly selectedNode = input<string | null>(null);
  readonly showSelectableRadioBtn = input(true);
  readonly nodeSelected = output<MatRadioChange>();

  treeControl = new NestedTreeControl<TreeViewNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeViewNode>();

  ngOnChanges() {
    const selectedNode = this.selectedNode();
    if (selectedNode) {
      this.expand(this.dataSource.data, selectedNode);
    } else {
      this.treeControl.expandAll();
    }
  }

  expand(data: TreeViewNode[], selectedNodeId: id) {
    if (data) {
      data.forEach((node) => {
        const hasSelectedChild = node.children?.map((c) =>
          JSON.stringify(c).includes('"id":"' + selectedNodeId),
        );
        if (hasSelectedChild?.includes(true)) {
          this.treeControl.expand(node);
          if (node.children) {
            this.expand(node.children, selectedNodeId);
          }
        }
      });
    }
  }

  hasChild = (_: number, node: TreeViewNode) =>
    !!node.children && node.children.length > 0;
}
