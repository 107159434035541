import { Injectable, inject } from '@angular/core';
import { Router, type UrlTree } from '@angular/router';
import { containsTree } from './contains-url-tree';

@Injectable({ providedIn: 'root' })
export class PreloadRegistryService {
  private router = inject(Router);

  // Using a Set so we don't add an element, on each hover.
  // This will generally reduce memory usage and will not require cleanup.
  private _queue = new Set<UrlTree>([]);

  add(route: UrlTree) {
    this._queue.add(route);
  }

  shouldPrefetch(url: string) {
    const tree = this.router.parseUrl(url);
    return [...this._queue].some(containsTree.bind(null, tree));
  }
}
