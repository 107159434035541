import { type CtrlChainIcons } from '../icons';

export enum Metrics {
  Water = 'water',
  Lives = 'lives',
  Land = 'land',
  Trees = 'trees',
}

export enum MetricIcons {
  Water = 'droplet',
  Lives = 'child',
  Land = 'hand-holding-seedling',
  Trees = 'tree',
}

export interface DetailsMetric {
  type: Metrics;
  label: string;
  value: number | null | undefined;
  icon: CtrlChainIcons;
  unitOfMeasure: string | null | undefined;
}
