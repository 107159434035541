import { Injectable, inject } from '@angular/core';
import { type AbstractControl } from '@angular/forms';
import { UserService } from '@cca-infra/user-management/v1';
import {
  type Observable,
  of,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailUniqueValidator {
  private userService = inject(UserService);

  userEmailUniqueValidator(exception?: string | null) {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: boolean } | null> => {
      const value = control.value as string;
      if (value === null || value?.length === 0) {
        return of(null);
      } else if (exception && exception === value) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap(() => {
            return this.userService.emailAvailable(control.value);
          }),
          map((isAvailable) => {
            if (isAvailable) return null;
            else return { emailNotAvailable: true };
          }),
          take(1),
        );
      }
    };
  }
}
