import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type GroupDefaultsViewModel,
  type UpdateGroupDefaultsRequest,
} from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GroupDefaultsService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getDefaults(groupId: id) {
    return this.http.get<GroupDefaultsViewModel>(
      `${this.url}detail/defaults/${groupId}`,
    );
  }

  updateDefaults(defaults: UpdateGroupDefaultsRequest) {
    return this.http.post<GroupDefaultsViewModel>(
      `${this.url}update/defaults`,
      defaults,
    );
  }
}
