import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { type CtrlChainIcons } from '@cca-common/core';
import { MatButtonModule } from '@angular/material/button';
import { UiLabelBadgeComponent } from '../badges';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-vertical-action-card',
  templateUrl: './vertical-action-card.component.html',
  styleUrls: ['./vertical-action-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, UiLabelBadgeComponent, IconComponent],
})
export class VerticalActionCardComponent {
  readonly tagName = input.required<string>();
  readonly description = input<string>();
  readonly buttonFormId = input<string>();
  readonly tagColor = input.required<
    'primary' | 'warning' | 'danger' | 'highlight'
  >();
  readonly buttonText = input.required<string>();
  readonly buttonColor = input.required<'primary' | 'warn' | 'accent'>();
  readonly buttonIcon = input.required<CtrlChainIcons>();
  readonly buttonType = input<'stroked' | 'flat'>('stroked');
  readonly buttonClick = output();
}
