import {
  ChangeDetectionStrategy,
  Component,
  input,
  type OnChanges,
  type SimpleChanges,
  output,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { type StopType } from '@cca-infra/order-management/v2';
import { IconComponent } from '../icon';

export interface MultiStop {
  index: number;
  stopType: StopType;
  stepIteration: number;
  title: string;
  address: string | undefined;
}

@Component({
  selector: 'cca-multi-stop-list',
  templateUrl: './multi-stop-list.component.html',
  styleUrls: ['./multi-stop-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgTemplateOutlet, MatExpansionModule, IconComponent],
})
export class MultiStopListComponent implements OnChanges {
  panelOpenState = false;
  intermediaryItems: MultiStop[] = [];
  firstExpandedItem: MultiStop | undefined;

  titleLabel = input<string | undefined>();
  items = input<MultiStop[]>([]);
  isSmallFormat = input(false);
  collapsedHeaderItem = input<MultiStop | undefined>();
  hideHeader = input(false);
  hideEdit = input(false);
  hasGrayHeader = input(false);
  isOverviewList = input(false);
  hasHover = input(true);

  readonly edit = output<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.intermediaryItems = [];
      this.firstExpandedItem = undefined;
      if (!this.isSmallFormat || this.items.length <= 3) {
        return;
      }
      this.intermediaryItems = this.items().filter(
        (l) => l.index > 1 && l.index !== this.items.length - 1,
      );
      this.firstExpandedItem = this.items().find((l) => l.index === 1);
    }
  }
}
