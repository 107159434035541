import { Injectable } from '@angular/core';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import {
  type GetUserRolePerSubgroupV2Request,
  type UpdateUserCredentialsRequest,
  type UpdateUserDetailRequest,
  type UpdateUserRolesRequest,
  type UserDetailV2ViewModel,
  type UserProfileHeaderViewModel,
  type UserRolePerSubgroupViewModel,
} from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class UserV2Service extends BaseApiService {
  constructor() {
    super(`user.v2.user`);
  }

  getUserProfileHeader(userId: id) {
    return this.http.get<UserProfileHeaderViewModel>(
      `${this.url}get-user-profile-header/${userId}`,
    );
  }

  getUserDetails(userId: id) {
    return this.http.get<UserDetailV2ViewModel>(
      `${this.url}get-user-details/${userId}`,
    );
  }

  getUserPermissions(request: GetUserRolePerSubgroupV2Request) {
    return this.http.post<UserRolePerSubgroupViewModel>(
      `${this.url}get-user-permissions-per-subgroup`,
      request,
    );
  }

  requestPasswordReset(userId: id) {
    return this.http.get(`${this.url}request-password-reset/${userId}`);
  }

  requestResetPassword(email: string) {
    return this.http.post<void>(
      `${this.url}request-reset-password`,
      {
        email: email,
      },
      {
        context: HttpContextBuilder({
          authentication: false,
        }),
      },
    );
  }

  updateUserCredentials(request: UpdateUserCredentialsRequest) {
    return this.http.put(`${this.url}update-user-credentials`, request);
  }

  updateUserDetails(request: UpdateUserDetailRequest) {
    return this.http.put(`${this.url}update-user-details`, request);
  }

  updateUserRoles(request: UpdateUserRolesRequest) {
    return this.http.put(`${this.url}update-user-roles`, request);
  }
}
