import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';
import { type ChargeType } from '../model';

/**
 * TODO: remove this interface when, swagger definition is updated to provide correct interface
 */

export interface GroupRate {
  articleDescription: string;
  articleId: id;
  articleNumber: number | string;
  chargeType: ChargeType;
  chargeTypeLabel: string;
  currencyType: string;
  rate: number;
  defaultRate: number;
  userGroupId: id;
}

@Injectable({
  providedIn: 'root',
})
export class UserGroupRateService extends BaseApiService {
  constructor() {
    super(`finance.v1.articleGroup`);
  }

  getGroupRates(enterpriseId: id) {
    return this.http.get<GroupRate[]>(`${this.url}rates/${enterpriseId}`);
  }

  updateGroupRate(enterpriseId: id, articleId: id, rate: number) {
    return this.http.put(`${this.url}rate`, {
      userGroupId: enterpriseId,
      articleId: articleId,
      rate: rate,
    });
  }

  resetGroupRate(enterpriseId: id, articleId: id) {
    return this.http.post(`${this.url}reset`, {
      userGroupId: enterpriseId,
      articleId: articleId,
    });
  }

  resetAllGroupRates(enterpriseId: id) {
    return this.http.post(`${this.url}reset/all`, {
      userGroupId: enterpriseId,
    });
  }
}
