import { Injectable, InjectionToken, inject } from '@angular/core';
import {
  type ActivatedRoute,
  NavigationEnd,
  type Params,
  Router,
  type RouterState,
} from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  type Observable,
  startWith,
  switchMap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
class RouterUtil {
  private router = inject(Router);

  state$: Observable<RouterState> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => {
      return this.router.routerState;
    }),
    startWith(this.router.routerState),
  );

  currentRoute$ = this.state$.pipe(
    map((state) => {
      let route = state.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
  );

  fragment$ = this.currentRoute$.pipe(
    switchMap((route) => route && route.fragment),
  );
  queryParams$ = this.currentRoute$.pipe(
    switchMap((route) => route && route.queryParams),
  );
  routeParams$ = this.currentRoute$.pipe(
    switchMap((route) => route && route.params),
  );
  routeParamsNested$ = this.state$.pipe(
    switchMap((state) => {
      let currentRoute: ActivatedRoute | null = state?.root;
      const params$: Observable<Params>[] = [];

      // Traverse through the route tree and collect the params observables
      while (currentRoute) {
        params$.push(currentRoute.params); // collect the observable
        currentRoute = currentRoute.firstChild;
      }

      // Combine the params observables and merge the results into one Params object
      // This works because combineLatest does preserve the order of the parameters into the resulting paramsArray
      return combineLatest(params$).pipe(
        map((paramsArray) => {
          // reduce the array into a single Params object
          return paramsArray.reduce((acc, params) => {
            return {
              ...acc,
              ...params,
            };
          }, {} as Params);
        }),
      );
    }),
  );
  routeData$ = this.currentRoute$.pipe(
    switchMap((route) => route && route.data),
  );

  routeParam$(param: string) {
    return this.routeParams$.pipe(map((params) => params && params[param]));
  }

  /**
   * constructs a route param stream with param
   */
  routeParamNested$(param: string) {
    return this.routeParamsNested$.pipe(
      map((params) => params && params[param]),
    );
  }

  /**
   * constructs a query param stream with param
   */
  queryParam$(param: string) {
    return this.queryParams$.pipe(map((params) => params && params[param]));
  }
}

// Define a type that combines the properties of Router and RouterUtil
export type RouterFacadeType = {
  [K in keyof Router | keyof RouterUtil]: K extends keyof Router
    ? Router[K]
    : K extends keyof RouterUtil
      ? RouterUtil[K]
      : never;
};

export const RouterFacade = new InjectionToken<RouterFacadeType>(
  'RouterFacade',
  {
    providedIn: 'root',
    factory: () => {
      const router = inject(Router);
      const util = inject(RouterUtil);

      return new Proxy(router, {
        get(target, keyProp) {
          // First, check if the property exists on the Router
          if (Reflect.has(router, keyProp)) {
            return Reflect.get(router, keyProp, router);
          }

          // Then, check if the property exists on the RouterUtil
          if (Reflect.has(util, keyProp)) {
            return Reflect.get(util, keyProp, util);
          }

          // If the property doesn't exist in both, throw an error
          throw new Error(
            `Property ${String(keyProp)} does not exist on Router or RouterUtil.`,
          );
        },
      }) as unknown as RouterFacadeType;
    },
  },
);
