import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PlatformEnvironmentToken } from '@cca-environment';
import { TranslocoModule } from '@jsverse/transloco';
@Component({
  selector: 'cca-error-page',
  imports: [TranslocoModule, MatButtonModule],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  environment = inject(PlatformEnvironmentToken);
  readonly title = input<string | null>();
  readonly paragraph = input<string | null>();
  readonly src = input<string | null>();
  readonly showButtons = input<boolean | null>();
}
