import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkCurrencyPipe } from '@cca-common/cdk';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'cca-booking-service-row',
  imports: [MatCheckboxModule, CdkCurrencyPipe],
  templateUrl: './booking-service-row.component.html',
  styleUrls: ['./booking-service-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingServiceRowComponent {
  readonly title = input('');
  readonly description = input('');
  readonly instructions = input<string | undefined>('');
  readonly image = input('');
  readonly price = input<number | null | undefined>(null);
  readonly includedText = input('');
  readonly currency = input<string | null>();
  readonly checked = input(false, {
    transform: coerceBooleanProperty,
  });
  readonly disabled = input(false);
  readonly showCheckMark = input(true);

  readonly serviceChanged = output<boolean>();
}
