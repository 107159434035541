import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';
import { type SetViewModel, type SetStatus } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ArticleSetService extends BaseApiService {
  constructor() {
    super(`finance.v1.set`);
  }

  createSet(orderId: id, orderArticleIds: string[] | id[]) {
    return this.http.post<SetViewModel>(`${this.url}create`, {
      orderId: orderId,
      orderArticleIds: orderArticleIds,
    });
  }

  getSetsByUserGroupId(userGroupId: id) {
    return this.http.get<SetViewModel[]>(`${this.url}group/${userGroupId}`);
  }

  updateStatus(setId: id, status: SetStatus) {
    return this.http.put<SetViewModel>(`${this.url}update`, {
      setId: setId,
      setStatus: status,
    });
  }

  deleteSet(setId: id) {
    return this.http.delete<SetViewModel>(`${this.url}${setId}`);
  }
}
