@if (isSmallFormat()) {
  <div
    class="multi-stop group/item px-6 py-2"
    [ngClass]="{ 'hover:surface-neutral-default': !!hasHover() }"
  >
    <div class="flex justify-between gap-2 group-hover/item:text-brand-default">
      <ng-template *ngTemplateOutlet="multiStop_Header"></ng-template>
    </div>
    <ng-template *ngTemplateOutlet="multiStop_Content"></ng-template>
  </div>
} @else {
  <ng-template *ngTemplateOutlet="multiStop_Header"></ng-template>
  <ng-template *ngTemplateOutlet="multiStop_Content"></ng-template>
}

<ng-template #multiStop_Header>
  @if (!hideHeader()) {
    <h3
      class="mb-1 self-center"
      [ngClass]="[
        isSmallFormat() ? 'text-sm' : '',
        hasGrayHeader() ? 'text-neutral-caption' : '',
      ]"
    >
      {{ titleLabel() }}
    </h3>
    @if (isSmallFormat() && !hideEdit()) {
      <cca-icon
        (click)="edit.emit()"
        class="invisible h-fit cursor-pointer text-xs text-brand-default group-hover/item:visible"
        icon="pencil"
      ></cca-icon>
    }
  }
</ng-template>

<ng-template #multiStop_Content>
  @for (item of items(); track item; let last = $last) {
    <ng-template
      *ngTemplateOutlet="
        multiStop_ListItem;
        context: { $implicit: item, last: last }
      "
    />
  }
</ng-template>

<ng-template #multiStop_ListItem let-item let-isLast="last">
  @if (item) {
    <div class="flex items-stretch gap-3">
      <div class="flex shrink-0 flex-col items-center">
        <div
          class="h-1 w-0.5 grow-0"
          [ngClass]="
            items().length > 1 && isLast ? 'surface-neutral-darker' : ''
          "
        ></div>
        <img
          class="mx-auto h-3 w-3 grow-0"
          [src]="'/assets/images/booking/location/circle.svg'"
        />
        <div
          class="w-0.5 flex-1 grow"
          [ngClass]="!isLast ? 'surface-neutral-darker' : ''"
        ></div>
      </div>
      <div class="word-break mb-1 flex flex-1 flex-col">
        <span
          class="w-full font-medium"
          [ngClass]="
            isOverviewList()
              ? 'text-xs text-neutral-caption'
              : 'text-sm text-neutral-body'
          "
          >{{ item.title }}</span
        >
        <span
          class="w-full font-normal text-neutral-body"
          [ngClass]="isOverviewList() ? 'text-base' : 'text-sm'"
        >
          @if (item.locationName) {
            <span
              class="w-full font-bold text-neutral-body"
              [ngClass]="isOverviewList() ? 'text-base' : 'text-sm'"
              >{{ item.locationName }}</span
            ><br />
          }
          {{ item.address }}</span
        >
      </div>
    </div>
  }
</ng-template>
