import * as i0 from '@angular/core';
import { InjectionToken, inject, NgZone, ɵisPromise as _isPromise, Injectable, input, Directive, Output, ElementRef, Component, ChangeDetectionStrategy, ViewChild, makeStateKey } from '@angular/core';
import { from, of, Observable, Subject, BehaviorSubject, defer } from 'rxjs';
import { map, tap, shareReplay, mergeMap, filter, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgStyle, NgClass } from '@angular/common';
const _c0 = ["container"];
const LOTTIE_OPTIONS = new InjectionToken('LottieOptions');
function convertPlayerOrLoaderToObservable() {
  const ngZone = inject(NgZone);
  const {
    player,
    useWebWorker
  } = inject(LOTTIE_OPTIONS);
  const playerOrLoader = ngZone.runOutsideAngular(() => player());
  // We need to use `isPromise` instead of checking whether
  // `result instanceof Promise`. In zone.js patched environments, `global.Promise`
  // is the `ZoneAwarePromise`. Some APIs, which are likely not patched by zone.js
  // for certain reasons, might not work with `instanceof`. For instance, the dynamic
  // import `() => import('./chunk.js')` returns a native promise (not a `ZoneAwarePromise`),
  // causing this check to be falsy.
  const player$ = _isPromise(playerOrLoader) ? from(playerOrLoader).pipe(map(module => module.default || module)) : of(playerOrLoader);
  return player$.pipe(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tap(player => player.useWebWorker?.(useWebWorker)), shareReplay({
    bufferSize: 1,
    refCount: true
  }));
}
class AnimationLoader {
  constructor() {
    this.player$ = convertPlayerOrLoaderToObservable().pipe(mergeMap(player => raf$(this.ngZone).pipe(map(() => player))));
    this.ngZone = inject(NgZone);
  }
  loadAnimation(options) {
    return this.player$.pipe(map(player => this.createAnimationItem(player, options)));
  }
  resolveOptions(options, container) {
    return Object.assign({
      container,
      renderer: 'svg',
      loop: true,
      autoplay: true
    }, options);
  }
  createAnimationItem(player, options) {
    return this.ngZone.runOutsideAngular(() => player.loadAnimation(options));
  }
  /** @nocollapse */
  static {
    this.ɵfac = function AnimationLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AnimationLoader)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AnimationLoader,
      factory: AnimationLoader.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimationLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function raf$(ngZone) {
  return new Observable(subscriber => {
    const requestId = ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
      subscriber.next();
      subscriber.complete();
    }));
    return () => cancelAnimationFrame(requestId);
  });
}
class CacheableAnimationLoader extends AnimationLoader {
  constructor() {
    super(...arguments);
    this.cache = new Map();
  }
  ngOnDestroy() {
    this.cache.clear();
  }
  loadAnimation(options) {
    return this.player$.pipe(map(player => {
      const animationItem = this.createAnimationItem(player, this.transformOptions(options));
      this.awaitConfigAndCache(options, animationItem);
      return animationItem;
    }));
  }
  awaitConfigAndCache(options, animationItem) {
    if (this.isAnimationConfigWithPath(options)) {
      // Don't wait for the `config_ready` event if it has been cached previously.
      if (this.cache.has(options.path)) {
        return;
      }
      animationItem.addEventListener('config_ready', () => {
        // See the comments below on why we're storing the animation data as a string.
        this.cache.set(options.path, JSON.stringify(animationItem['animationData']));
      });
    }
  }
  transformOptions(options) {
    if (this.isAnimationConfigWithPath(options) && this.cache.has(options.path)) {
      return {
        ...options,
        path: undefined,
        // Caretaker note: `lottie-web` cannot re-use the `animationData` object between animations, and we
        // have to retrieve a new object each time an animation is created.
        // https://github.com/airbnb/lottie-web#html
        // See comments for the `animationData` property.
        animationData: JSON.parse(this.cache.get(options.path))
      };
    } else {
      return options;
    }
  }
  isAnimationConfigWithPath(options) {
    return typeof options.path === 'string';
  }
  /** @nocollapse */
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCacheableAnimationLoader_BaseFactory;
      return function CacheableAnimationLoader_Factory(__ngFactoryType__) {
        return (ɵCacheableAnimationLoader_BaseFactory || (ɵCacheableAnimationLoader_BaseFactory = i0.ɵɵgetInheritedFactory(CacheableAnimationLoader)))(__ngFactoryType__ || CacheableAnimationLoader);
      };
    })();
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CacheableAnimationLoader,
      factory: CacheableAnimationLoader.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CacheableAnimationLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function provideCacheableAnimationLoader() {
  return [{
    provide: AnimationLoader,
    useExisting: CacheableAnimationLoader
  }];
}
function provideLottieOptions(options) {
  return [{
    provide: LOTTIE_OPTIONS,
    useValue: options
  }];
}
class BaseDirective {
  constructor() {
    this.options = input(null);
    this.containerClass = input(null);
    this.styles = input(null);
    /**
     * `animationCreated` is dispatched after calling `loadAnimation`.
     */
    this.animationCreated = this.getAnimationItem();
    /**
     * `complete` is dispatched after completing the last frame.
     */
    this.complete = this.awaitAnimationItemAndStartListening('complete');
    /**
     * `loopComplete` is dispatched after completing the frame loop.
     */
    this.loopComplete = this.awaitAnimationItemAndStartListening('loopComplete');
    /**
     * `enterFrame` is dispatched after entering the new frame.
     */
    this.enterFrame = this.awaitAnimationItemAndStartListening('enterFrame');
    /**
     * `segmentStart` is dispatched when the new segment is adjusted.
     */
    this.segmentStart = this.awaitAnimationItemAndStartListening('segmentStart');
    /**
     * Original event name is `config_ready`. `config_ready` is dispatched
     * after the needed renderer is configured.
     */
    this.configReady = this.awaitAnimationItemAndStartListening('config_ready');
    /**
     * Original event name is `data_ready`. `data_ready` is dispatched
     * when all parts of the animation have been loaded.
     */
    this.dataReady = this.awaitAnimationItemAndStartListening('data_ready');
    /**
     * Original event name is `DOMLoaded`. `DOMLoaded` is dispatched
     * when elements have been added to the DOM.
     */
    this.domLoaded = this.awaitAnimationItemAndStartListening('DOMLoaded');
    /**
     * `destroy` will be dispatched when the component gets destroyed,
     * it's handy for releasing resources.
     */
    this.destroy = this.awaitAnimationItemAndStartListening('destroy');
    /**
     * `error` will be dispatched if the Lottie player could not render
     * some frame or parse config.
     */
    this.error = this.awaitAnimationItemAndStartListening('error');
    this.ngZone = inject(NgZone);
    this.animationLoader = inject(AnimationLoader);
    this.loadAnimation$ = new Subject();
    this.animationItem$ = new BehaviorSubject(null);
    this.setupLoadAnimationListener();
  }
  ngOnDestroy() {
    this.destroyAnimation();
  }
  loadAnimation(changes, container) {
    this.ngZone.runOutsideAngular(() => this.loadAnimation$.next([changes, container]));
  }
  getAnimationItem() {
    return defer(() => this.animationItem$).pipe(filter(animationItem => animationItem !== null));
  }
  awaitAnimationItemAndStartListening(name) {
    return this.getAnimationItem().pipe(switchMap(animationItem =>
    // `fromEvent` will try to call `removeEventListener` when `unsubscribe()` is invoked.
    // The problem is that `ngOnDestroy()` is called before Angular unsubscribes from
    // `@Output()` properties, thus `animationItem` will be `null` already, also `lottie-web`
    // removes event listeners when calling `destroy()`.
    new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        animationItem.addEventListener(name, event => {
          this.ngZone.runOutsideAngular(() => {
            observer.next(event);
          });
        });
      });
    })));
  }
  setupLoadAnimationListener() {
    const loadAnimation$ = this.loadAnimation$.pipe(filter(([changes]) =>
    // `!ngServerMode` is equal to `isBrowser`.
    typeof ngServerMode !== 'undefined' && !ngServerMode && changes.options !== undefined));
    loadAnimation$.pipe(switchMap(([changes, container]) => {
      this.destroyAnimation();
      return this.animationLoader.loadAnimation(this.animationLoader.resolveOptions(changes.options.currentValue, container));
    }), takeUntilDestroyed()).subscribe(animationItem => {
      this.ngZone.run(() => this.animationItem$.next(animationItem));
    });
  }
  destroyAnimation() {
    const animationItem = this.animationItem$.getValue();
    // The `ng-lottie` component or the `lottie` directive can be destroyed
    // before the `animationItem` is set, thus it will fail with
    // `Cannot read property 'destroy' of null`.
    // Potentially it can happen if the directive gets destroyed before change
    // detection is run.
    if (animationItem === null) {
      return;
    }
    // `destroy()` will remove all events listeners.
    animationItem.destroy();
    this.animationItem$.next(null);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BaseDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BaseDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BaseDirective,
      selectors: [["", "lottie", ""]],
      inputs: {
        options: [1, "options"],
        containerClass: [1, "containerClass"],
        styles: [1, "styles"]
      },
      outputs: {
        animationCreated: "animationCreated",
        complete: "complete",
        loopComplete: "loopComplete",
        enterFrame: "enterFrame",
        segmentStart: "segmentStart",
        configReady: "configReady",
        dataReady: "dataReady",
        domLoaded: "domLoaded",
        destroy: "destroy",
        error: "error"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseDirective, [{
    type: Directive,
    args: [{
      selector: '[lottie]'
    }]
  }], () => [], {
    animationCreated: [{
      type: Output
    }],
    complete: [{
      type: Output
    }],
    loopComplete: [{
      type: Output
    }],
    enterFrame: [{
      type: Output
    }],
    segmentStart: [{
      type: Output
    }],
    configReady: [{
      type: Output
    }],
    dataReady: [{
      type: Output
    }],
    domLoaded: [{
      type: Output
    }],
    destroy: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class LottieDirective extends BaseDirective {
  constructor() {
    super(...arguments);
    this.host = inject(ElementRef);
  }
  ngOnChanges(changes) {
    super.loadAnimation(changes, this.host.nativeElement);
  }
  /** @nocollapse */
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLottieDirective_BaseFactory;
      return function LottieDirective_Factory(__ngFactoryType__) {
        return (ɵLottieDirective_BaseFactory || (ɵLottieDirective_BaseFactory = i0.ɵɵgetInheritedFactory(LottieDirective)))(__ngFactoryType__ || LottieDirective);
      };
    })();
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LottieDirective,
      selectors: [["", "lottie", ""]],
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieDirective, [{
    type: Directive,
    args: [{
      selector: '[lottie]',
      standalone: true
    }]
  }], null, null);
})();
class LottieComponent extends BaseDirective {
  constructor() {
    super(...arguments);
    this.width = input(null);
    this.height = input(null);
    this.container = null;
  }
  ngOnChanges(changes) {
    super.loadAnimation(changes, this.container.nativeElement);
  }
  /** @nocollapse */
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLottieComponent_BaseFactory;
      return function LottieComponent_Factory(__ngFactoryType__) {
        return (ɵLottieComponent_BaseFactory || (ɵLottieComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LottieComponent)))(__ngFactoryType__ || LottieComponent);
      };
    })();
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LottieComponent,
      selectors: [["ng-lottie"]],
      viewQuery: function LottieComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
        }
      },
      inputs: {
        width: [1, "width"],
        height: [1, "height"]
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 6,
      consts: [["container", ""], [3, "ngStyle", "ngClass"]],
      template: function LottieComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.width() || "100%")("height", ctx.height() || "100%");
          i0.ɵɵproperty("ngStyle", ctx.styles())("ngClass", ctx.containerClass());
        }
      },
      dependencies: [NgStyle, NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieComponent, [{
    type: Component,
    args: [{
      selector: 'ng-lottie',
      template: `
    <div
      #container
      [style.width]="width() || '100%'"
      [style.height]="height() || '100%'"
      [ngStyle]="styles()"
      [ngClass]="containerClass()"
    ></div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [NgStyle, NgClass]
    }]
  }], null, {
    container: [{
      type: ViewChild,
      args: ['container', {
        static: true
      }]
    }]
  });
})();
function transformAnimationFilenameToKey(animation) {
  const [animationName] = animation.split('.json');
  return `animation-${animationName}`;
}
class LottieTransferState {
  constructor(transferState) {
    this.transferState = transferState;
  }
  get(animation) {
    const animationKey = transformAnimationFilenameToKey(animation);
    const stateKey = makeStateKey(animationKey);
    return this.transferState.get(stateKey, null);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function LottieTransferState_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LottieTransferState)(i0.ɵɵinject(i0.TransferState));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LottieTransferState,
      factory: LottieTransferState.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieTransferState, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.TransferState
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationLoader, BaseDirective, LottieComponent, LottieDirective, LottieTransferState, provideCacheableAnimationLoader, provideLottieOptions, transformAnimationFilenameToKey, CacheableAnimationLoader as ɵCacheableAnimationLoader, LOTTIE_OPTIONS as ɵLOTTIE_OPTIONS };
