import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type CarrierCommunicationViewModel,
  type OrderAutomationViewModel,
  type OrderDetailV2ViewModel,
  type PaginationOrdersV2ViewModel,
  type UpdateLegDesiredDatesRequest,
  type UpdateOrderLegLocationRequest,
  type UpdateOrderReferencesRequest,
} from '../model';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseApiService {
  constructor() {
    super(`order.v2.orders`);
  }

  detailsByReference(referenceNumber: string) {
    return this.http.get<OrderDetailV2ViewModel>(
      `${this.url}details-by-reference/${referenceNumber}`,
    );
  }

  getCarrierCommunicationText(orderId: id) {
    return this.http.get<CarrierCommunicationViewModel>(
      `${this.url}carrier-communication/${orderId}`,
    );
  }

  getIsUserOrderShipper(referenceNumber: id) {
    return this.http.get<boolean>(
      `${this.url}is-user-order-shipper/${referenceNumber}`,
    );
  }

  updateLegLocation(leg: UpdateOrderLegLocationRequest) {
    return this.http.post<boolean>(`${this.url}update-leg-location`, leg);
  }

  updateLegReferences(legReferences: UpdateOrderReferencesRequest) {
    return this.http.post<boolean>(
      `${this.url}update-order-references`,
      legReferences,
    );
  }

  updateDesiredDates(legDesiredDates: UpdateLegDesiredDatesRequest) {
    return this.http.post<void>(
      `${this.url}update-leg-desired-dates`,
      legDesiredDates,
    );
  }

  orderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<PaginationOrdersV2ViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getOrderAutomations(orderId: id) {
    return this.http.get<OrderAutomationViewModel>(
      `${this.url}order-automations/${orderId}`,
    );
  }
}
