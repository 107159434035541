import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterFacade } from '@cca-common/cdk';
import { DomainGroupType } from '@cca-infra/user-management/v1';
import { type GroupHeaderDataViewModel } from '@cca-infra/user-management/v2';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { SectionHeaderComponent } from '../section-header';

@Component({
  imports: [TranslocoModule, MatButtonModule, SectionHeaderComponent],
  selector: 'cca-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
  providers: [provideTranslocoScope('group')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInfoComponent {
  private router = inject(RouterFacade);

  canUpdateGroup = input.required<boolean>();
  editBaseLink = input.required<string>();
  userGroup = input.required<GroupHeaderDataViewModel | null>();

  groupAvatar = computed(() => this.userGroup()?.brandingImageUrl);
  isInternalGroup = computed(() => {
    return (
      this.userGroup()?.groupTypes?.find(
        (x) => x.domainGroupType === DomainGroupType.Internal,
      ) ?? false
    );
  });

  editClick() {
    this.router.navigate([
      `${this.editBaseLink()}/${this.userGroup()?.userGroupId}`,
    ]);
  }
}
