/**
 * User API
 * <p>The User API is used to manage users of the platform. Users van login, change passwords.                                      It will also provide an access and refresh token which is used for other API access.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = General  2 = Enterprise  4 = WarehouseCustomer  8 = Location  16 = Carrier  32 = Shipper  64 = FinanceGeneral  128 = FinanceCustomer  256 = FinanceSupplier  512 = Internal  1024 = WarehouseSupplier  2048 = CarbonSupplier  4096 = ManagedTransShipper
 */
export const DomainGroupType = {
  Undefined: 0,
  General: 1,
  Enterprise: 2,
  WarehouseCustomer: 4,
  Location: 8,
  Carrier: 16,
  Shipper: 32,
  FinanceGeneral: 64,
  FinanceCustomer: 128,
  FinanceSupplier: 256,
  Internal: 512,
  WarehouseSupplier: 1024,
  CarbonSupplier: 2048,
  ManagedTransShipper: 4096,
} as const;

export type DomainGroupType =
  (typeof DomainGroupType)[keyof typeof DomainGroupType];

/**
 * Maps a DomainGroupType enumeration value to its corresponding translation key.
 *
 * @param { DomainGroupType } domainGroupType -
 *        The enumeration value representing the type of DomainGroupType.
 * @returns {string} The translation key corresponding to the given DomainGroupType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('DomainGroupType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromDomainGroupType(DomainGroupType.General);
 * console.log(translationKey); // 'DomainGroupType.General'
 * console.log(translocoService.translate(translationKey)) // "Translation of DomainGroupType.General"
 *
 * // using in a template:
 * {{ translationKeyFromDomainGroupType(DomainGroupType.General) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('DomainGroupType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromDomainGroupType(
  domainGroupType: DomainGroupType,
) {
  switch (domainGroupType) {
    case DomainGroupType.Undefined:
      return 'DomainGroupType.Undefined';
    case DomainGroupType.General:
      return 'DomainGroupType.General';
    case DomainGroupType.Enterprise:
      return 'DomainGroupType.Enterprise';
    case DomainGroupType.WarehouseCustomer:
      return 'DomainGroupType.WarehouseCustomer';
    case DomainGroupType.Location:
      return 'DomainGroupType.Location';
    case DomainGroupType.Carrier:
      return 'DomainGroupType.Carrier';
    case DomainGroupType.Shipper:
      return 'DomainGroupType.Shipper';
    case DomainGroupType.FinanceGeneral:
      return 'DomainGroupType.FinanceGeneral';
    case DomainGroupType.FinanceCustomer:
      return 'DomainGroupType.FinanceCustomer';
    case DomainGroupType.FinanceSupplier:
      return 'DomainGroupType.FinanceSupplier';
    case DomainGroupType.Internal:
      return 'DomainGroupType.Internal';
    case DomainGroupType.WarehouseSupplier:
      return 'DomainGroupType.WarehouseSupplier';
    case DomainGroupType.CarbonSupplier:
      return 'DomainGroupType.CarbonSupplier';
    case DomainGroupType.ManagedTransShipper:
      return 'DomainGroupType.ManagedTransShipper';
    default:
      return '';
  }
}
