import {
  ChangeDetectionStrategy,
  Component,
  type OnInit,
  input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { CdkInputLocaleDirective } from '@cca-common/cdk';

@Component({
  selector: 'cca-loading-operation',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    CdkInputLocaleDirective,
  ],
  templateUrl: './loading-operation.component.html',
  styleUrls: ['./loading-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOperationComponent implements OnInit {
  readonly loadingOperationTitle = input<string>();
  readonly liveLoadingForm = input<FormControl>(new FormControl());
  readonly liveLoadingLabel = input<string>();
  readonly hoursLabel = input<string>();
  readonly loadingHoursForm = input<FormControl>(new FormControl());
  readonly hourSymbolLabel = input<string>();
  readonly amountErrorMsg = input<string>();

  changed() {
    this.liveLoadingFormChanges();
  }

  ngOnInit(): void {
    // only self is important here so we are not trigger a valueChanges on the root form
    // since this would make it seem that the user changed something while it was in fact this component
    // that triggered the status/value changes
    this.liveLoadingFormChanges({ onlySelf: true });
  }

  liveLoadingFormChanges(
    opts?:
      | {
          onlySelf?: boolean | undefined;
          emitEvent?: boolean | undefined;
        }
      | undefined,
  ) {
    if (this.liveLoadingForm().value) {
      this.loadingHoursForm().enable(opts);
      this.loadingHoursForm().setValidators(Validators.required);
    } else {
      this.loadingHoursForm().disable(opts);
      this.loadingHoursForm().removeValidators(Validators.required);
    }
    this.loadingHoursForm().updateValueAndValidity();
  }
}
