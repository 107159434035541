import {
  ChangeDetectionStrategy,
  Component,
  type OnInit,
  viewChild,
  output,
  input,
} from '@angular/core';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { NgClass } from '@angular/common';

import { type id } from '@cca-infra/common';
import {
  ActionHeaderComponent,
  Flavor,
  type OrderActionLike,
} from './action-header';

@Component({
  selector: 'cca-user-card-radio',
  imports: [NgClass, ActionHeaderComponent, MatRadioModule],
  templateUrl: './user-card-radio.component.html',
  styleUrls: ['./user-card-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardRadioComponent implements OnInit {
  Flavor = Flavor;
  readonly label = input<string>();
  readonly description = input<string>();
  readonly isSelected = input(false);
  readonly value = input<id>();

  readonly radioChanged = output<id | null>();

  readonly radioBtn = viewChild(MatRadioButton);
  action: OrderActionLike = {} as OrderActionLike;

  ngOnInit() {
    this.action = {
      label: this.label() ?? '',
      shortDescription: this.description() ?? '',
      iterationNumber: undefined,
      reDoActionLabel: undefined,
    };
  }
}
