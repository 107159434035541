import {
  Component,
  ChangeDetectionStrategy,
  type OnChanges,
  type SimpleChanges,
  isDevMode,
  input,
} from '@angular/core';
import { CdkCurrencyPipe } from '@cca-common/cdk';

@Component({
  imports: [CdkCurrencyPipe],
  selector: 'cca-price-cell',
  templateUrl: './price-cell.component.html',
  styleUrls: ['./price-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPriceCellComponent implements OnChanges {
  readonly currency = input<string | null>();
  readonly price = input<number | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.currency?.currentValue && isDevMode()) {
      console.warn(
        'PriceCell received a currencyType of null, if this was intended you can ignore this warning',
      );
    }
  }
}
