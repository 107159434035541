import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type CarrierCorridorGroupDetailViewModel,
  type CarrierCorridorRegionViewModel,
  type PaginatedCarrierCorridorViewModel,
  type UpdateCarrierCorridorsRequest,
} from '../model';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class CarrierCorridorService extends BaseApiService {
  constructor() {
    super(`location.v1.carrierCorridor`);
  }

  getCarrierCorridor(userGroupId: string) {
    return this.http.get<CarrierCorridorGroupDetailViewModel>(
      `${this.url}detail/${userGroupId}`,
    );
  }

  updateCarrierCorridor(request: UpdateCarrierCorridorsRequest) {
    return this.http.post(`${this.url}update`, request);
  }

  getRegionOptions() {
    return this.http.get<CarrierCorridorRegionViewModel[]>(
      `${this.url}region-options`,
    );
  }

  paginatedSearch(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginatedCarrierCorridorViewModel>
    >(`${this.url}paginated-search`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }
}
