<div
  [ngClass]="{
    primary: flavor() === 'primary',
    warning: flavor() === 'warning',
    danger: flavor() === 'danger',
    highlight: flavor() === 'highlight',
    default: flavor() === 'default',
  }"
  [class.w-full]="fullWidth()"
  [@inOutAnimation]
  class="flex w-full items-center gap-2 rounded-lg px-4 py-3"
>
  @if (showIcon()) {
    <cca-icon
      class="text-2xl leading-6 grayscale-mode-neutral"
      [icon]="flavor() === 'danger' ? 'circle-exclamation' : 'info'"
    ></cca-icon>
  }
  <p class="text-base grayscale-mode-neutral">
    {{ description() }}
  </p>

  @if (showButton()) {
    <button
      (click)="buttonClick()"
      class="ml-2 rounded-md border-2 border-cca-neutrals-300 p-2 font-medium text-cca-neutrals-700"
    >
      {{ buttonLabel() }}
    </button>
  }
  @if (closable()) {
    <button mat-icon-button (click)="close()" class="ml-2">
      <cca-icon
        icon="circle-xmark"
        class="text-xl !grayscale-mode-neutral"
      ></cca-icon>
    </button>
  }
</div>
