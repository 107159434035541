import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type AcceptSelectedLaneRowBidsRequest,
  type AuditLogItemViewModel,
  type CarrierGroupOptionsViewModel,
  type CreateLanesTenderRequest,
  type GetLaneRowBidsRequest,
  type LaneRowBidViewModel,
  type LaneRowTenderDetailViewModel,
  type LanesRequestTenderDetailViewModel,
  type LanesTenderViewModel,
  type PaginationLaneRowItemViewModel,
  type PaginationLanesTenderItemViewModel,
  type ReadLaneTenderAuditLogsRequest,
  type RespondNotInterestedToLaneTenderRequest,
  type SelectLaneRowBidRequest,
  type UnselectLaneRowBidRequest,
  type UpdateLanesTenderRequest,
} from '../model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TenderLaneService extends BaseApiService {
  constructor() {
    super(`tender.v1.lane`);
  }

  tenderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationLanesTenderItemViewModel>
    >(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  allPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  laneRowTenderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<PaginationLaneRowItemViewModel>>(
      `${this.url}paginated-lane-tender-rows`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  laneRowTenderPaginationUnauthorized(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<PaginationLaneRowItemViewModel>>(
      `${this.url}paginated-lane-tender-rows-unauthorized`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  laneRowTenderPaginationFilters() {
    return this.http.get(`${this.url}paginated-lane-tender-row-filters`);
  }

  laneRowTenderExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}export-carrier-lane-tender-rows`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  createTenderRequest(request: CreateLanesTenderRequest) {
    return this.http.post(`${this.url}create`, request);
  }

  updateTenderRequest(request: UpdateLanesTenderRequest) {
    return this.http.post(`${this.url}update`, request);
  }

  closeTenderRequest(request: { tenderId: id }) {
    return this.http.post(`${this.url}close`, request);
  }

  getTenderByReference(tenderId: id) {
    return this.http.get<LanesTenderViewModel>(
      `${this.url}detail-by-reference/${tenderId}`,
    );
  }

  getLaneRowTenderDetails(laneRowId: id) {
    return this.http.get<LaneRowTenderDetailViewModel>(
      `${this.url}lane-row-tender-details/${laneRowId}`,
    );
  }

  notInterested(request: RespondNotInterestedToLaneTenderRequest) {
    return this.http.post(`${this.url}respond-not-interested`, request);
  }

  notInterestedUnauthorized(request: RespondNotInterestedToLaneTenderRequest) {
    return this.http.post(
      `${this.url}respond-not-interested-unauthorized`,
      request,
    );
  }

  getLaneRowBids(request: GetLaneRowBidsRequest) {
    return this.http.post<LaneRowBidViewModel[]>(
      `${this.url}lane-row-bids`,
      request,
    );
  }

  laneTenderRowBid(tenderRequestRowCarrierId: id) {
    return this.http.get<LaneRowBidViewModel>(
      `${this.url}lane-row-bid/${tenderRequestRowCarrierId}`,
    );
  }

  laneTenderRowBidUnauthorized(
    tenderReferenceNumber: string,
    laneRowId: id,
    hash: string,
  ) {
    return this.http.get<LaneRowBidViewModel>(
      `${this.url}lane-row-bid-unauthorized/${tenderReferenceNumber}/${laneRowId}/${hash}`,
    );
  }

  getCarrierGroupOptionsForCarrier() {
    return this.http.get<CarrierGroupOptionsViewModel[]>(
      `${this.url}get-carrier-group-options-for-carrier`,
    );
  }

  getEventLog(request: ReadLaneTenderAuditLogsRequest) {
    return this.http.post<AuditLogItemViewModel[]>(
      `${this.url}read-lanerequest-auditlogs`,
      request,
    );
  }

  unSaveSelectedCarrierBid(request: SelectLaneRowBidRequest) {
    return this.http.post<boolean>(`${this.url}unselect-lane-row-bid`, request);
  }

  readyToOffer(request: AcceptSelectedLaneRowBidsRequest) {
    return this.http.post<boolean>(
      `${this.url}acccept-selected-lane-row-bids`,
      request,
    );
  }

  uploadBids(carrierGroupId: string, file: File) {
    const formData = new FormData();
    formData.set('file', file, file.name);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    return this.http.post(
      `${this.url}import-carrier-lane-tender-row-bids/${carrierGroupId}`,
      formData,
      { headers },
    );
  }

  getLaneRequestTenderTabDetails(laneRequestId: id) {
    return this.http.get<LanesRequestTenderDetailViewModel>(
      `${this.url}lanes-request-tender-details/${laneRequestId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  getLaneTenderRowFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}paginated-lane-tender-row/get-filter-options/${filterKey}`,
    );
  }

  saveSelectedCarrierBid(request: UnselectLaneRowBidRequest) {
    return this.http.post<boolean>(`${this.url}select-lane-row-bid`, request);
  }
}
