import { Observable, ReplaySubject, Subject } from 'rxjs';
import { type HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { inject, isDevMode, NgZone } from '@angular/core';

export class SignalRConnection {
  private currentConnection: HubConnection | null = null;
  private subject = new Subject<string>();
  private started = new ReplaySubject<void>(1);
  private stopped = new ReplaySubject<void>(1);
  private zone = inject(NgZone);

  get events$() {
    return this.subject.asObservable();
  }

  get started$() {
    return this.started.asObservable();
  }

  get stopped$() {
    return this.stopped.asObservable();
  }

  constructor(private hubUrl: string) {
    // on new token
    let firstConnection = true;
    this.zone.runOutsideAngular(() => {
      if (this.currentConnection) {
        this.currentConnection?.stop();
      }

      // build connection
      this.currentConnection = new HubConnectionBuilder()
        .withUrl(hubUrl, {
          withCredentials: true,
        })
        //Enable logging for testing
        //.configureLogging(LogLevel.Trace)
        .withAutomaticReconnect()
        .build();

      this.bindConnectionMessage();
      this.currentConnection.start().then(() => {
        if (firstConnection) {
          this.zone.run(() => this.started.next());
          firstConnection = false;
        }
      });
    });
  }

  bindConnectionMessage() {
    if (!this.currentConnection) {
      throw Error('Not connected to SignalR');
    }

    // Create a function that the hub can call to broadcast messages.
    this.currentConnection.on('broadcastmessage', this.messageCallback);
    this.currentConnection.onclose(this.onConnectionError);

    if (isDevMode()) {
      this.currentConnection.onreconnecting = () => {
        console.warn(`SignalR:${this.hubUrl} is reconnecting`);
      };
      this.currentConnection.onreconnected = () => {
        console.warn(`SignalR:${this.hubUrl} is reconnected`);
      };
    }
  }

  messageCallback = (message: string) => {
    this.zone.run(() => {
      if (!message) return;
      this.subject.next(message);
    });
  };

  onConnectionError = (err: unknown) => {
    if (err) {
      this.subject.error(err);
    } else {
      this.subject.complete();
    }

    this.close();
  };

  send(methodName: string, ...args: unknown[]) {
    return new Observable((observer) => {
      if (!this.currentConnection) {
        observer.error(Error('Not connected to SignalR'));
        return;
      }

      this.currentConnection
        .send(methodName, ...args)
        .then((result) => this.zone.run(() => observer.next(result)))
        .catch((error) => this.zone.run(() => observer.error(error)))
        .finally(() => this.zone.run(() => observer.complete()));
    });
  }

  invoke(methodName: string, ...args: unknown[]) {
    return new Observable((observer) => {
      if (!this.currentConnection) {
        observer.error(Error('Not connected to SignalR'));
        return;
      }

      this.currentConnection
        .invoke(methodName, ...args)
        .then((result) => this.zone.run(() => observer.next(result)))
        .catch((error) => this.zone.run(() => observer.error(error)))
        .finally(() => this.zone.run(() => observer.complete()));
    });
  }

  close() {
    // stop signalR connection
    this.currentConnection?.stop();

    this.zone.run(() => {
      // stop the subject
      this.subject.complete();
      this.stopped.next();
      this.stopped.complete();
      this.started.complete();
    });
  }
}
