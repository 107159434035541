import { NgClass } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  output,
  input,
  model,
  effect,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IconComponent } from '../icon';

@Component({
  imports: [
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    IconComponent,
  ],
  selector: 'cca-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent {
  readonly enter = output<string>();
  readonly search = model<string | null>(null);
  readonly searchCleared = output<void>();
  readonly searchLabel = input('');

  searchField = new FormControl('');

  constructor() {
    this.searchField.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => this.search.set(value ?? ''));

    effect(() => {
      const search = this.search() ?? '';
      if (this.searchField.value !== search) {
        this.searchField.patchValue(search);
      }
    });
  }

  clearSearch() {
    this.searchField.setValue('');
    this.searchCleared.emit();
  }
}
